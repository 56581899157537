import { isNotEmpty, useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Group, NumberInput, Stack, Text } from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { LoanParams } from 'app/models/loan-params'
import { getPiks } from 'app/state/ducks/payment-in-kind/selectors'
import { createDraftPIK, submitPIK } from './pik-daml-actions'
import { PikParams } from 'app/models/pik-params'

type Props = {
  close: any
  loan: LoanParams
}

export default function ProcessPIKForm({ close, loan }: Props) {
  const piks = useSelector(getPiks)
  const businessDate = useSelector(getBusinessDate)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [pik, setPik] = useState<PikParams | undefined>()

  useEffect(() => {
    const pik = piks.find(pik => pik.loanId.id === loan.id)
    setPik(pik)
  }, [piks, loan])

  const form: any = useForm({
    initialValues: {
      id: pik?.id,
      percentage: pik?.percentage ? Number(pik.percentage) : 0,
      pikDate: pik?.pikDate
        ? stringToDate(pik.pikDate)
        : loan.nextPIKDate
          ? stringToDate(loan.nextPIKDate)
          : businessDate,
    },

    transformValues: values => {
      return {
        id: values.id,
        percentage: values.percentage,
        loanId: { id: loan.id, admin: loan.agencyAdmin },
        pikDate: formatDateToUTC(values.pikDate),
      }
    },

    validate: {
      pikDate: isNotEmpty('PIK Date'),
      percentage: (value) => {
        if (!value) {
          return 'PIK percentage is required';
        }
        if (value < 0 || value > 100) {
          return 'PIK percentage must be between 0 and 100';
        }
        return null;
      },
    },
  })

  useEffect(() => {
    if (!pik) return
    form.setValues({
      id: pik?.id,
      percentage: Number(pik?.percentage),
      pikDate: stringToDate(pik.pikDate),
    })
  }, [pik])

  const onSubmit = async () => {
    setIsLoading(true)
    const result = await createDraftPIK(
      form.getTransformedValues(),
      dispatch,
      pik,
    )
    setIsLoading(false)
    if (result) {
      close()
    }
  }

  const saveAndSubmit = async () => {
    setIsLoading(true)
    const response: any = await createDraftPIK(
      form.getTransformedValues(),
      dispatch,
      pik,
    )
    if (!response) {
      setIsLoading(false)
      return
    }
    await submitPIK(response, dispatch)

    close()
    setIsLoading(false)
  }

  return (
    <FormWrapper title={(pik ? 'Edit' : 'Create') + " Payment In Kind"}>
      <div className="content">
        <form onSubmit={form.onSubmit(() => onSubmit())}>
          <div className="create-new">
            <Stack spacing="xl">
              {pik && pik?.status !== 'Draft' ? (
                <Text className="topFormErrorText">
                  PIK has already been submitted
                </Text>
              ) : null}
              <Group noWrap>
                <CustomDatePicker
                  disabled={pik && pik?.status === 'Submitted'}
                  required
                  label={'PIK Date'}
                  date={form.values.pikDate}
                  setDate={(value: any) =>
                    form.setFieldValue('pikDate', value)
                  }
                  holidayCalendars={[]}
                />
                <NumberInput
                  withAsterisk
                  w="100%"
                  precision={5}
                  min={0}
                  max={100}
                  label="PIK Percentage"
                  parser={value =>
                    value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                  }
                  formatter={value =>
                    !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                  }
                  {...form.getInputProps('percentage')}
                />
              </Group>
              <Group noWrap>
                <PrimaryButton
                  loading={isLoading}
                  type="submit"
                  disabled={!form.isValid() || pik?.status === 'Submitted'}
                  text="Save"
                  w="100%"
                />
                <PrimaryButton
                  loading={isLoading}
                  onClick={() => saveAndSubmit()}
                  disabled={!form.isValid() || pik?.status === 'Submitted'}
                  text="Create and Submit"
                  w="100%"
                />
              </Group>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
