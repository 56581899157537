import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { Group, Stack, Text } from '@mantine/core'
import { amortizationsStatus } from 'app/models/dropdown-options'
import { setOneFacility } from 'app/state/ducks/facilities/thunks'
import { getFacilitySchedulerAmortization } from 'app/state/ducks/amortization/selectors'
import {
  AmortizationScheduleParams,
  SchedulerList
} from 'app/models/amortitation-schedule'
import AmortizationRowActions from './amortization-row-actions'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { formatDateToShow, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import MainTable from '../Table/MainTable'
import { colsAmoritzationSchedule } from './amortization-schedule-columnDefs'


interface Props {
  facilityId?: string
  isCalculationInSync?: boolean
  setIsCalculationInSync?: any
  tab: string
}
export default function AmortizationScheduleDetails({
  facilityId,
  isCalculationInSync,
  setIsCalculationInSync,
  tab
}: Props) {
  const dispatch = useDispatch()
  const facilityAmendments = useSelector(getFacilityAmendments)
  const facility = useSelector(getFacility)
  const businessDate = useSelector(getBusinessDate)
  const facilityAmortizationScheduler: AmortizationScheduleParams = useSelector(
    getFacilitySchedulerAmortization
  )

  const [amortizationSchedule, setAmortizationSchedule] =
    useState<AmortizationScheduleParams>()
  const [currentRows, setCurrentRows] = useState<any>([])
  const [historyRows, setHistoryRows] = useState<any>([])

  useEffect(() => {
    if (facilityAmendments && facilityAmendments.length > 0) {
      const amendment = facilityAmendments.find(
        (amendment: any) => amendment.id === facilityId
      )
      if (amendment) {
        dispatch(setOneFacility(amendment))
      }
    }
  }, [facilityAmendments, facilityId])

  useEffect(() => {
    checkAvailablePaymentSum()
  }, [facility, amortizationSchedule])

  useEffect(() => {
    if (facility && facility.amendmentAmortization) {
      setAmortizationSchedule(facility.amendmentAmortization)
    } else {
      setAmortizationSchedule(facilityAmortizationScheduler)
    }
  }, [facilityAmortizationScheduler, facility])

  useEffect(() => {
    // Current rows (not 'Paid')
    const currentData = amortizationSchedule?.scheduler
      ?.filter((scheduler: SchedulerList) => scheduler.status !== 'Paid')
      .map((scheduler: SchedulerList) => ({
        paymentDueDate: formatDateToShow(scheduler?.paymentDate ? stringToDate(scheduler.paymentDate) : undefined),
        facilityAmount: formatNumberToCurrency(
          Number(scheduler?.amountDue) ?? 0,
          facility?.baseCurrency ?? 'USD'
        ),
        paymentAmount: formatNumberToCurrency(
          Number(scheduler?.paymentAmount) ?? 0,
          facility?.baseCurrency ?? 'USD'
        ),
        balance: formatNumberToCurrency(
          Number(scheduler?.balance) ?? 0,
          facility?.baseCurrency ?? 'USD'
        ),
        status:
          amortizationsStatus.find(({ value }) => value == scheduler?.status)
            ?.label || '',
        actions: (
          <AmortizationRowActions
            row={scheduler}
            amortization={amortizationSchedule}
            canBePaid={
              scheduler ===
              amortizationSchedule.scheduler.find(
                (sched) => sched.status !== 'Paid'
              ) &&
              (facility?.status === 'Approved' ||
                (facility?.amendmentType !== undefined &&
                  facility?.status === 'Draft'))
            }
            canBePastDue={
              stringToDate(scheduler.paymentDate) < businessDate &&
              (facility?.status === 'Approved' ||
                (facility?.amendmentType !== undefined &&
                  facility?.status === 'Draft'))
            }
            facility={facility}
          />
        ),
      }));
    setCurrentRows(currentData);

    // History rows ('Paid')
    const historyData = amortizationSchedule?.scheduler
      ?.filter((scheduler: SchedulerList) => scheduler.status === 'Paid')
      .map((scheduler: SchedulerList) => ({
        paymentDueDate: formatDateToShow(scheduler?.paymentDate ? stringToDate(scheduler.paymentDate) : undefined),
        facilityAmount: formatNumberToCurrency(
          Number(scheduler?.amountDue) ?? 0,
          facility?.baseCurrency ?? 'USD'
        ),
        paymentAmount: formatNumberToCurrency(
          Number(scheduler?.paymentAmount) ?? 0,
          facility?.baseCurrency ?? 'USD'
        ),
        balance: formatNumberToCurrency(
          Number(scheduler?.balance) ?? 0,
          facility?.baseCurrency ?? 'USD'
        ),
      }));
    setHistoryRows(historyData);
  }, [amortizationSchedule]);

  const checkAvailablePaymentSum = () => {
    const availablePaymentSum = amortizationSchedule?.scheduler
      ?.filter(scheduler => scheduler?.status !== 'Paid')
      .reduce((sum, scheduler) => sum + Number(scheduler?.paymentAmount), 0)
    setIsCalculationInSync(
      Number(facility?.amount ?? 0) === availablePaymentSum
    )
  }

  return (
    <Stack>
      <Group noWrap w="100%" style={{ fontSize: 'large', fontWeight: '500' }}>
        {tab === 'amortizationScheduleCurrent' && !isCalculationInSync && (
          <Text className="topFormErrorText">
            The schedule is not in sync with facility amount
          </Text>
        )}
      </Group>

      <MainTable
        tableName='Amortization Schedule'
        rowsPerPage={5}
        enableColumnResizing={true}
        withPagination={true}
        csvExportEnabled={true}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        overflow="auto"
        minHeight="10px"
        columnDefs={colsAmoritzationSchedule}
        data={tab === 'amortizationScheduleCurrent' ? currentRows : historyRows}
        setRow={() => { }}
      />
    </Stack>
  )
}
