import { BusinessDateParams } from 'app/models/business-date-params'
import * as types from './types'

export const setBusinessDate = (payload: BusinessDateParams) => ({
  type: types.SET_BUSINESS_DATE,
  payload,
})

export const addBusinessDate = (payload: any) => ({
  type: types.ADD_BUSINESS_DATE,
  payload,
})
