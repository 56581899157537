import { NumberInput, Stack } from '@mantine/core';
import { formatNumberToCurrency } from 'app/utils/util-functions';
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle';
import React from 'react';

type Props = {
  form: any
  loading: boolean
}

export function TradeEntryFacilitiesSection({ form, loading }: Props) {
  return (
    <Stack className="tradeEntrySection">
      {/* Info: Facilities Table */}
      <SectionTitle>Traded Facilities</SectionTitle>
      <div className='tradeEntryInputGroup'>
        <table className="tradeEntryTable">
          <thead>
            <tr>
              <th>Facility Name</th>
              <th style={{ borderBottom: "1px solid #71c0ee" }}>Traded Amount</th>
              <th style={{ borderBottom: "1px solid #71c0ee" }}>Price</th>
              <th>CUSIP</th>
              <th>LX ID</th>
              <th>Facility ID</th>
              <th>Client Facility ID</th>
            </tr>
          </thead>
          <tbody>
            {form.values.tradedFacilities.map((tradeFacility: any, index: number) => {
              return (
                <tr key={index}>
                  <td className='tradeEntryTdBold'>{tradeFacility.facility.name ?? ''}</td>
                  <td className='tradeEntryTdInput'>
                    <NumberInput
                      disabled={loading}
                      parser={value => value.replace(/[^0-9.]/g, '')}
                      formatter={value =>
                        !Number.isNaN(parseFloat(value))
                          ? formatNumberToCurrency(Number(
                            parseFloat(
                              value.replace(
                                /\B(?<!\d*)(?=(\d{3})+(?!\d))/g,
                                ','
                              )
                            )
                          ), tradeFacility.facility?.baseCurrency ?? 'USD')
                          : '$ 0'
                      }
                      {...form.getInputProps(`tradedFacilities.${index}.tradeAmount`)}
                    />
                  </td>
                  <td className='tradeEntryTdInput'>
                    <NumberInput
                      disabled={loading}
                      precision={5}
                      parser={value =>
                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                      }
                      formatter={value =>
                        !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                      }
                      {...form.getInputProps(`tradedFacilities.${index}.price`)}
                    /></td>
                  <td>{tradeFacility.facility.cusip}</td>
                  <td>{tradeFacility.facility.lxId}</td>
                  <td>{tradeFacility.facility.systemId ?? ''}</td>
                  <td></td>
                </tr>
              )
            })}
          </tbody>
        </table >
      </div>
    </Stack>
  );
}