import { LoanParams } from 'app/models/loan-params'
import { Stack } from '@mantine/core'
import { formatDateToShow, formatNumberToCurrency, numberOfDaysBetweenTwoDates, stringToDate } from 'app/utils/util-functions'
import MainTable from '../Table/MainTable'
import { getColumnDefs } from './table-columnDefs-loan-summary'

type Props = {
  loan: LoanParams
}

export default function CurrentLoanSummary({ loan }: Props) {
  return (
    <Stack spacing={0}>
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Current Loan Summary
      </div>
      <MainTable
        tableName='Loan History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs()}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        data={[{
          'Start Date': formatDateToShow(loan?.startDate ? stringToDate(loan.startDate) : undefined),
          'End Date': formatDateToShow(loan?.endDate ? stringToDate(loan.endDate) : undefined),
          'Loan Amount': formatNumberToCurrency(Number(loan.amount), loan.currency),
          'Base Rate': loan.interestBaseRate + "%",
          'Margin': loan.margin + "%",
          'All-In Rate': loan.allInRate + "%",
          'Days': Math.round(numberOfDaysBetweenTwoDates(new Date(loan.startDate), new Date(loan.endDate))),
          'Interest Due': formatNumberToCurrency(Number(loan.accrualInterestMaturityDate), loan.currency)
        }]}
        setRow={() => {
        }}
        withPagination={false}
        minHeight={'100px'}
      />
    </Stack>

  )
}
