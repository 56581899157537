import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { frequencyOptions } from 'app/models/dropdown-options'
import { ChangeFeeData } from 'app/models/fees-params'
import { formatDateToShow, stringToDate } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function getColumnDefs(): MRT_ColumnDef<ChangeFeeData>[] {
  return [
    {
      size: 40,
      accessorKey: 'feeType',
      header: 'Fee Type',
      Header: <TableHead header={'Fee Type'} />,
      Cell: ({ cell }) => {
        return (
          <Text>
            {cell.getValue<string>()
              ?.toString()
              .replace(/([A-Z])/g, ' $1')
              .trim()}
          </Text>
        )
      },
    },
    {
      size: 40,
      accessorKey: 'feeRate',
      header: 'Rate',
      Header: <TableHead header={'Rate'} />,
      Cell: ({ cell }: any) => (
        <TableRowText text={Number(cell.getValue()) + '%'} />
      ),
    },
    {
      size: 40,
      accessorKey: 'frequency',
      header: 'Frequency',
      Header: <TableHead header={'Frequency'} />,
      Cell: ({ cell }) => {
        return (
          <Text>
            {
              frequencyOptions.find((e: any) => e.value === cell.getValue<string>())
                ?.label
            }
          </Text>
        )
      },
    },
    {
      size: 40,
      accessorKey: 'firstPaymentDate',
      header: 'First Payment Date',
      Header: <TableHead header={'First Payment Date'} />,
      Cell: ({ cell }) => {
        return <Text>{formatDateToShow(cell.getValue<string>() ? stringToDate(cell.getValue<string>()) : undefined)}</Text>
      },
    },
  ]
}
