import { LoanParams } from 'app/models/loan-params'
import {
    Group,
    NumberInput,
    Select,
    SelectItem,
    Stack,
    Switch,
    Text,
} from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import {
    currencyOptions,
    dayBasisOptions,
    roundingOptions,
    pikOptions,
} from 'app/models/dropdown-options'
import {
    submitForApproval,
} from 'app/state/ducks/loan-rollovers/thunks'
import FormWrapperWithSubtitle from '../common/form-wrapper-with-subtitle'
import {
    ErrorNotification,
    SuccessNotification,
} from 'app/views/components/notifications/notification'
import { useState } from 'react'
import { ContractPeriod, FacilityParams, IroValues } from 'app/models/facility-params'
import { useDispatch } from 'react-redux'
import { LoanRolloverParams } from 'app/models/loan-rollover-params'
import {
    Option,
} from 'app/models/dropdown-options'
import { formatNumberToCurrency, roundTo } from 'app/utils/util-functions'


type Props = {
    form: any
    close: any
    facility: FacilityParams | undefined
    loan: LoanParams
    rollover: LoanRolloverParams | undefined
    facilityindexOptions: Option[]
    createDraftRollover: () => Promise<any>
    onMarginChange: (value: number | '') => void
    onInterestBaseRateChange: (value: number | '') => void
    changeContractPeriod: (event: string | null) => void
    handleWithPikChange: (event: any) => void
    changeIndexOption: (event: string | null) => void
    facilityContractPeriodOptions: SelectItem[]
    calculateInterests: (iro: IroValues | undefined, contractPeriod: ContractPeriod | undefined, startDate: Date) => void
    iro: IroValues | undefined
    contractPeriod: ContractPeriod | undefined
    isContractPeriodDisabled: boolean
}

export default function RolloverFormPresentation({
    form,
    close,
    facility,
    loan,
    rollover,
    iro,
    contractPeriod,
    facilityindexOptions,
    facilityContractPeriodOptions,
    isContractPeriodDisabled,
    createDraftRollover,
    onMarginChange,
    onInterestBaseRateChange,
    changeContractPeriod,
    handleWithPikChange,
    changeIndexOption,
    calculateInterests,

}: Props) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async () => {
        setIsLoading(true)
        const result = await createDraftRollover()
        setIsLoading(false)
        if (result) close()
    }

    const saveAndSubmit = async () => {
        setIsLoading(true)
        const response: any = await createDraftRollover()
        try {
            if (!response) {
                return
            }
            const result: any = await dispatch(
                submitForApproval(response.payload.data)
            )
            if (result.success) {
                SuccessNotification({
                    title: 'Successful Rollover Submitted',
                    message: 'loan rollover subbmitted succesfuly',
                })
            } else {
                ErrorNotification({
                    title: 'Rollover created but not submitted.',
                    message: result.payload ?? 'Could not submit Loan Rollover',
                })
            }
        } finally {
            close()
            setIsLoading(false)
        }
    }

    const changePikPercentage = (e: any) => {
        form.setFieldValue('pikPercentage', e)
        const pikAmount = loan?.accrualInterestMaturityDate * (e / 100)
        const interestPaymentAmount = loan?.accrualInterestMaturityDate - pikAmount
        form.setFieldValue('pikAmount', pikAmount)
        form.setFieldValue('interestPaymentAmount', interestPaymentAmount)
        const amount = Number(loan.amount) + pikAmount
        form.setFieldValue('amount', amount)
    }

    const changePikAmount = (e: any) => {
        let pikAmount = e;
        let pikPercentage = (e / loan?.accrualInterestMaturityDate) * 100;
        if (pikPercentage > 100) {
            pikPercentage = 100;
            pikAmount = loan?.accrualInterestMaturityDate;
        }

        const interestPaymentAmount = loan?.accrualInterestMaturityDate - pikAmount;
        const amount = Number(loan.amount) + pikAmount;
        form.setFieldValue('pikAmount', Number(pikAmount));
        form.setFieldValue('pikPercentage', Number(pikPercentage));
        form.setFieldValue('interestPaymentAmount', interestPaymentAmount);
        form.setFieldValue('amount', Number(amount));
    };

    return (
        <FormWrapperWithSubtitle
            title={
                (rollover ? 'Edit' : 'Create') +
                ' Rollover' +
                (facility?.pikOption !== 'No_PIK' ? ' w/PIK' : '')
            }
            subtitle={
                'Existing Loan Amount: ' + formatNumberToCurrency(Number(loan?.amount ?? 0.0) ?? 0, loan?.currency ?? 'USD')
            }
        >
            <div className="content">
                <form onSubmit={form.onSubmit(() => onSubmit())}>
                    <div className="create-new">
                        <Stack spacing="xl">
                            {rollover && rollover?.status !== 'Draft' ? (
                                <Text className="topFormErrorText">
                                    Rollover has already been submitted
                                </Text>
                            ) : null}
                            {facility?.pikOption !== 'No_PIK' && (
                                <Group noWrap>
                                    <div style={{ width: '100%' }}>
                                        <Stack style={{ marginTop: '15px' }}>
                                            <Group>
                                                <Switch
                                                    labelPosition="left"
                                                    label=" PIK (ON/OFF)"
                                                    size="lg"
                                                    checked={form.values.withPik}
                                                    onChange={handleWithPikChange}
                                                    value={form.values.withPik}
                                                />
                                            </Group>
                                        </Stack>
                                    </div>
                                    <Select
                                        searchable
                                        readOnly
                                        label="Pik Options"
                                        w="100%"
                                        data={[...pikOptions]}
                                        value={facility?.pikOption}
                                    />
                                    <NumberInput
                                        withAsterisk
                                        readOnly
                                        precision={2}
                                        w="100%"
                                        label="Accrued Interest"
                                        id="accruead Interest"
                                        parser={value =>
                                            value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                        }
                                        formatter={value =>
                                            !Number.isNaN(parseFloat(value ?? ''))
                                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                : ''
                                        }
                                        value={
                                            Number(loan?.accrualInterestMaturityDate ?? 0.0) ?? 0.0
                                        }
                                    />
                                </Group>
                            )}
                            <Group noWrap>
                                <Select
                                    searchable
                                    withAsterisk
                                    label="Index Rate"
                                    placeholder="Select Index Rate"
                                    w="100%"
                                    data={facilityindexOptions}
                                    {...form.getInputProps('interestRateOption')}
                                    onChange={e => {
                                        changeIndexOption(e)
                                    }}
                                />
                                <Select
                                    searchable
                                    withAsterisk
                                    readOnly
                                    data={currencyOptions}
                                    label="Select Currency"
                                    placeholder="Currency"
                                    w="100%"
                                    {...form.getInputProps('currency')}
                                />
                                {form.values.withPik && (
                                    <NumberInput
                                        withAsterisk
                                        w="100%"
                                        precision={5}
                                        min={0}
                                        max={100}
                                        label="PIK Percentage"
                                        parser={value =>
                                            value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                        }
                                        formatter={value =>
                                            !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                                        }
                                        {...form.getInputProps('pikPercentage')}
                                        onChange={e => {
                                            changePikPercentage(e)
                                        }}
                                    />
                                )}

                                {!form.values.withPik && (<NumberInput
                                    withAsterisk
                                    readOnly={form.values.withPik}
                                    precision={2}
                                    w="100%"
                                    label="Amount"
                                    id="amount"
                                    placeholder="Enter Amount"
                                    parser={value =>
                                        value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? ''))
                                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            : ''
                                    }
                                    {...form.getInputProps('amount')}
                                />)}
                            </Group>

                            {form.values.withPik && (
                                <Group noWrap>
                                    <NumberInput
                                        precision={2}
                                        w="100%"
                                        label="PIK Amount"
                                        parser={value =>
                                            value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                        }
                                        formatter={value =>
                                            !Number.isNaN(parseFloat(value ?? ''))
                                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                : ''
                                        }
                                        {...form.getInputProps('pikAmount')}
                                        onChange={e => {
                                            changePikAmount(e)
                                        }}
                                    />
                                    <NumberInput
                                        readOnly={form.values.withPik}
                                        precision={2}
                                        w="100%"
                                        label="Interest Payment"
                                        parser={value =>
                                            value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                        }
                                        formatter={value =>
                                            !Number.isNaN(parseFloat(value ?? ''))
                                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                : ''
                                        }
                                        {...form.getInputProps('interestPaymentAmount')}
                                    />
                                    <NumberInput
                                        {...form.getInputProps('pikAmount')}
                                        readOnly={form.values.withPik}
                                        precision={2}
                                        w="100%"
                                        label="Rollover Amount"
                                        value={
                                            (Number(form.values.pikAmount ?? 0) ?? 0) +
                                            (Number(loan?.amount ?? 0) ?? 0)
                                        }
                                        parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
                                        formatter={(value) =>
                                            !Number.isNaN(parseFloat(value ?? ''))
                                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                : ''
                                        }
                                    />
                                </Group>)}
                            <Stack>
                                <Select
                                    searchable
                                    disabled={isContractPeriodDisabled}
                                    label="Contract Period"
                                    placeholder="Select Contract Period"
                                    w="100%"
                                    data={facilityContractPeriodOptions}
                                    {...form.getInputProps('contractPeriod')}
                                    onChange={e => {
                                        changeContractPeriod(e)
                                    }}
                                />
                                <Group noWrap>
                                    <CustomDatePicker
                                        required
                                        label={'Start Date'}
                                        date={form.values.startDate}
                                        setDate={(value: any) => {
                                            form.setFieldValue('startDate', value)
                                            calculateInterests(iro, contractPeriod, value)
                                        }}
                                        holidayCalendars={facility?.holidayCalendar ?? []}
                                    />

                                    <CustomDatePicker
                                        label={'Maturity Date'}
                                        date={form.values.maturity}
                                        setDate={(value: any) =>
                                            form.setFieldValue('maturity', value)
                                        }
                                        holidayCalendars={facility?.holidayCalendar ?? []}
                                    />

                                    <Select
                                        searchable
                                        readOnly
                                        data={dayBasisOptions}
                                        label="Day basis"
                                        w="100%"
                                        {...form.getInputProps('dayBasis')}
                                    />
                                </Group>
                                {(form.isTouched('startDate') || form.isTouched('maturity')) &&
                                    form.values.maturity &&
                                    new Date(form.values.startDate) >=
                                    new Date(form.values.maturity) ? (
                                    <div className="topFormErrorText">
                                        Start Date Must Be Before Maturity Date
                                    </div>
                                ) : null}
                            </Stack>

                            <Group noWrap>
                                <NumberInput
                                    w="100%"
                                    precision={5}
                                    label="Interest Rate "
                                    parser={value =>
                                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                                    }
                                    {...form.getInputProps('interestBaseRate')}
                                    onChange={onInterestBaseRateChange}
                                />

                                <NumberInput
                                    w="100%"
                                    precision={5}
                                    label="Interest Rate with Rounding"
                                    parser={value =>
                                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                                    }
                                    {...form.getInputProps('roundedBaseRate')}
                                />

                                <Select
                                    searchable
                                    data={roundingOptions}
                                    readOnly
                                    label="Interest Base Rate Rounding"
                                    w="100%"
                                    {...form.getInputProps('rounding')}
                                />
                            </Group>

                            <Group noWrap>
                                <NumberInput
                                    w="100%"
                                    precision={5}
                                    label="Margin"
                                    parser={value =>
                                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                                    }
                                    {...form.getInputProps('margin')}
                                    onChange={onMarginChange}
                                />

                                {form.values.casValue !== undefined && form.values.casValue !== 0 && (
                                    <NumberInput
                                        readOnly
                                        w="100%"
                                        precision={5}
                                        label="CAS Rate"
                                        parser={value =>
                                            value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                        }
                                        formatter={value =>
                                            !Number.isNaN(parseFloat(value ?? '')) ? roundTo(Number(value ?? ''), form?.values?.rounding ?? 'NoRounding') + ' %' : '%'
                                        }
                                        {...form.getInputProps('casValue')}
                                    />
                                )}

                                <NumberInput
                                    w="100%"
                                    precision={5}
                                    label="All In Rate"
                                    parser={value =>
                                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? '')) ? roundTo(Number(value ?? ''), form?.values?.rounding ?? 'NoRounding') + ' %' : '%'
                                    }
                                    {...form.getInputProps('allInRate')}
                                />
                            </Group>
                            <Group noWrap>
                                <PrimaryButton
                                    loading={isLoading}
                                    type="submit"
                                    disabled={!form.isValid()}
                                    text="Save"
                                    w="100%"
                                />
                                <PrimaryButton
                                    loading={isLoading}
                                    onClick={() => saveAndSubmit()}
                                    disabled={!form.isValid()}
                                    text="Create and Submit"
                                    w="100%"
                                />
                            </Group>
                        </Stack>
                    </div>
                </form>
            </div>
        </FormWrapperWithSubtitle>
    )
}