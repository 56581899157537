import React, { useState, useEffect } from 'react'
import { Group, Modal, Select, Stack, TextInput, Text } from '@mantine/core'
import { HolidayCalendarInfoParams, HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useForm } from '@mantine/form'
import { calendarYears } from 'app/models/dropdown-options'
import { DatePicker } from '@mantine/dates';
import { activateCalendar, saveCalendar } from 'app/state/ducks/holiday-calendars/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { formatDateToShow, formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import IonIcon from '@reacticons/ionicons'

type HolidayCalendarModalProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  holidayCalendar?: HolidayCalendarParams
  updateCalendar: (calendar: HolidayCalendarParams) => void
}

export const HolidayCalendarModal: React.FC<HolidayCalendarModalProps> = ({
  setOpen,
  open,
  holidayCalendar,
  updateCalendar,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [calendarSelection, setCalendarSelection] = useState<Date[]>([]);
  const [holidayInfo, setHolidayInfo] = useState<HolidayCalendarInfoParams[] | []>(holidayCalendar?.holidayInfo ?? []);
  const businessDate: Date = useSelector(getBusinessDate)
  const [date, setDate] = useState(businessDate);

  useEffect(() => {
    if (!holidayCalendar) {
      form.reset();
      setHolidayInfo([]);
      setCalendarSelection([]);
    } else {
      form.setValues({
        name: holidayCalendar.name,
        year: holidayCalendar.year,
        holidayName: '',
        status: holidayCalendar.status ?? 'inactive'
      });
      setHolidayInfo(holidayCalendar.holidayInfo ?? []);
    }
  }, [holidayCalendar]);

  const form: any = useForm({
    initialValues: {
      name: holidayCalendar?.name ?? '',
      year: holidayCalendar?.year ?? '',
      holidayName: '',
      status: holidayCalendar?.status ?? 'inactive'
    },
    validate: {
      name: value => (value === '' ? 'Required' : null),
      year: value => (value === '' ? 'Required' : null),
    },
    transformValues: values => ({
      name: values.name,
      year: values.year,
      holidayInfo: holidayInfo,
      status: values.status,
    }),
    validateInputOnBlur: true,
  })

  const addHolidayToList = () => {
    const holidayName = form.values.holidayName
    const holidayDates = calendarSelection
    const holidays: HolidayCalendarInfoParams[] = []
    if (holidayName && holidayDates.length > 0) {
      holidayDates.forEach((date: Date) => {
        const holiday = {
          holidayName: holidayName,
          holidayDate: formatDateToUTC(date),
        }
        holidays.push(holiday)
      })
      const combinedHolidays = [...holidayInfo, ...holidays];
      combinedHolidays.sort((a, b) => a.holidayDate.localeCompare(b.holidayDate));
      setHolidayInfo(combinedHolidays)
      setCalendarSelection([])
      form.values.holidayName = ''
    }
  }

  const removeHolidayFromList = (holiday: HolidayCalendarInfoParams) => {
    const newHolidayInfo = holidayInfo.filter((info: HolidayCalendarInfoParams) => info != holiday);
    setHolidayInfo(newHolidayInfo)
  }

  const submitCalendar = async (holidayCalendar: HolidayCalendarParams, oldHolidayCalendar?: HolidayCalendarParams) => {
    const response: any = await dispatch(saveCalendar(holidayCalendar, oldHolidayCalendar))
    if (response?.success) {
      SuccessNotification({
        title: 'Saved Holiday Calendar',
      })
      updateCalendar(response.payload.data)
    } else {
      ErrorNotification({
        title: 'Holiday Calendar Failed to Save',
        message: response.payload ?? 'Check data and try again',
      })
    }
    return response
  }

  async function submitForm(event: { preventDefault: () => void }) {
    event.preventDefault()
    setIsLoading(true)
    try {
      await submitCalendar(form.getTransformedValues(), holidayCalendar)
      setIsLoading(false)
      setOpen(false)
    } catch {
      ErrorNotification({
        title: 'Holiday Calendar Failed to Save',
        message: 'Check data and try again',
      })
      setIsLoading(false)
    }
  }

  const submitAndActivate = async () => {
    setIsLoading(true)
    const response: any = await submitCalendar(form.getTransformedValues(), holidayCalendar)
    try {
      if (!response.success) {
        return
      }
      const result: any = await dispatch(
        activateCalendar(response.payload.data)
      )
      if (result.success) {
        SuccessNotification({
          title: 'Activated Holiday Calendar',
        })
      } else {
        ErrorNotification({
          title: 'Holiday Calendar Failed to activate',
          message: response.payload ?? 'Check data and try again',
        })
      }
    } finally {
      close()
      setIsLoading(false)
      setOpen(false)
    }
  }

  const yearChange = (year: string) => {
    form.setFieldValue('year', year)
    const curMonth = businessDate.getMonth()
    setDate(() => new Date(Number(year), curMonth));
  }

  return (
    <Modal
      className="modal-body create-new-form fit-content-modal"
      opened={open}
      onClose={() => setOpen(!open)}
      centered={true}
      size="60%"
    >
      <FormWrapper
        title={`${holidayCalendar ? 'Edit' : 'Add'} Holiday Calendar`}>
        <div className="content">
          <form onSubmit={submitForm}>
            <div className="create-new" style={{ paddingTop: '15px' }}>
              <Group noWrap w="100%">
                <Stack w="100%">
                  <Group noWrap w="100%">
                    <TextInput
                      w="100%"
                      label="Calendar Name"
                      name="Calendar Name"
                      id="name"
                      placeholder="Enter Calendar Name"
                      {...form.getInputProps('name')}
                    />
                  </Group>
                  <Group noWrap w="100%">
                    <Select
                      w="100%"
                      label="Calendar Year"
                      placeholder="Select Calendar Year"
                      data={calendarYears}

                      onChange={(event: string) => {
                        if (event) {
                          yearChange(event)
                        }
                      }}
                      value={form.values.year}
                    />
                  </Group>
                  <Group noWrap w="100%">
                    <Stack w="100%">
                      <Text className='holidaySelectorLabel'>Select Dates</Text>
                      <DatePicker
                        className='holidaySelector'
                        type="multiple"
                        firstDayOfWeek={0}
                        date={date}
                        value={calendarSelection}
                        onChange={setCalendarSelection}
                        onDateChange={setDate}
                      />
                    </Stack>
                  </Group>
                  <Group noWrap w="100%">
                    <TextInput
                      w="100%"
                      label="Holiday Name"
                      name="Holiday Name"
                      id="holidayName"
                      placeholder="Holiday Name"
                      {...form.getInputProps('holidayName')}
                    />
                  </Group>
                  <Group noWrap w="100%">
                    <IonIcon name="add-circle-outline" onClick={() => addHolidayToList()} />
                    <Text w='100%' className='holidayFormAdd'>
                      Add Holiday
                    </Text>
                  </Group>
                  <div
                    style={{
                      display: 'flex',
                      gap: '.5rem',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <PrimaryButton
                      disabled={!form.isValid()}
                      className="form-button"
                      loading={isLoading}
                      type="submit"
                      w="100%"
                    >
                      Submit
                    </PrimaryButton>
                    <PrimaryButton
                      disabled={!form.isValid()}
                      className="form-button"
                      loading={isLoading}
                      w="100%"
                      onClick={() => submitAndActivate()}
                    >
                      Submit and Activate
                    </PrimaryButton>
                  </div>
                </Stack>
                <Stack w="60%" className='holidayCalendarFormSummary'>
                  <Group noWrap w="100%" position="center">
                    <Text className='holidayCalendarFormSummaryTitle'>
                      Summary
                    </Text>
                  </Group>
                  <Group w='100%' noWrap position="apart">
                    <Text w='100%' className='holidayCalendarFormSummarySubTitles'>
                      Holiday Name
                    </Text>
                    <Text w='100%' className='holidayCalendarFormSummarySubTitles'>Holiday Date</Text>
                    <Text w='10%'></Text>
                  </Group>
                  {holidayInfo ? holidayInfo.map((holiday: HolidayCalendarInfoParams, i: number) => {
                    return (
                      <Group w='100%' noWrap position="apart" key={i + ' holidaycalendarForm'}>
                        <Text w='100%'>
                          {holiday.holidayName}
                        </Text>

                        <Text w='100%'>{formatDateToShow(holiday?.holidayDate ? stringToDate(holiday.holidayDate) : undefined)}</Text>
                        <IonIcon size="large" name="trash-outline" className="click-ion-icon" style={{ color: "red" }}
                          onClick={() =>
                            removeHolidayFromList(holiday)
                          } />
                      </Group>
                    )
                  }) : null}
                </Stack>
              </Group>
            </div>
          </form>
        </div>
      </FormWrapper >
    </Modal >
  )
}