import { useEffect, useState } from 'react'
import { Col, Grid, Stack } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { getJobs } from 'app/state/ducks/jobs/selectors'
import MainTable from 'app/views/components/Table/MainTable'
import { JobParams } from 'app/models/job-params'
import { loadAllJobs } from 'app/state/ducks/jobs/thunks'
import { JobsExpand } from './job-expand/job-expand-component'
import { TableData, manageJobsCols } from './job-manage-table-columnDefs'
import { Group } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { getBusinessDateInfo } from 'app/state/ducks/business-date/selectors'
import { loadBusinessDate, nextBusinessDate } from 'app/state/ducks/business-date/thunks'
import { stringToDate } from 'app/utils/util-functions'


export function ManageJobs() {
  const dispatch = useDispatch()
  const jobs: JobParams[] = useSelector(getJobs)
  const [loading, setLoading] = useState(false);
  const [jobLogs, setJobLogs] = useState<JobParams>()
  const [tableData, setTableData] = useState<TableData[]>([])
  const businessDateInfo = useSelector(getBusinessDateInfo)


  useEffect(() => {
    dispatch(loadAllJobs())
    dispatch(loadBusinessDate())
  }, [dispatch])

  useEffect(() => {
    if (!jobs || jobs.length === 0) return
    setTableData(
      jobs.map(data => ({ ...data, setJobLogs: setJobLogs }))
    )
  }, [jobs])

  const handleClick = async () => {
    try {
      setLoading(true);
      await dispatch(nextBusinessDate(businessDateInfo));
      await dispatch(loadBusinessDate());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack>
      <Grid gutter="lg">
        <Col span={jobLogs ? 7 : 12}>
          <div style={{ height: '100%' }}>
            <Group position="apart" style={{ paddingBottom: '10px' }}>
              <PageTitle>Job Management</PageTitle>
            </Group>
            <h3>Business Date</h3>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', maxWidth: '25%', minWidth: "fit-content" }}>
              <CustomDatePicker
                disabled={true}
                label=""
                date={businessDateInfo?.currentDate ? stringToDate(businessDateInfo?.currentDate) : new Date()}
                setDate={() => null}
                holidayCalendars={[]}
              />
              <PrimaryButton text="Update" onClick={handleClick} loading={loading} disabled={loading} />
            </div>
            <div className='jobSummaryTitle'>
              <h6 style={{ margin: 0, color: 'white' }}>Job Summary</h6>
            </div>

            <MainTable
              tableName='Job Table'
              withPagination={false}
              columnDefs={manageJobsCols}
              data={tableData ?? []}
              enableTopToolbar={false}
              headerBackgroundColor='#F0EEEE'
              setRow={(e: any, cellName: string) => {
                if (cellName != 'id') {
                  //   navigate('/entitymanagement/entity/' + e.id + '/' + e.status)
                }
              }}
            />
          </div>
        </Col>
        {jobLogs && (
          <Col span={5}>
            <JobsExpand
              index={jobLogs as any}
              closeJobExpand={() => setJobLogs(undefined)}
            />
          </Col>
        )}
      </Grid>
    </Stack>
  )
}