import { Anchor, Breadcrumbs, Stack, Text } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import {
  WireInstruction,
  Entity
} from '../../common/borrowers-wireinstructions-params'
import { DealParams } from 'app/models/deal-params'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { getColumnDefs } from '../../common/table-columnDefs-borrower'
import { cleanBorrowers } from '../../common/clean-borrowers'
import TableList from 'app/views/components/Table/table-list'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import MainTable from 'app/views/components/Table/MainTable'
import { getCurrencyDescription } from 'app/utils/util-functions'

export default function GuarantorsPageDeal() {
  const navigate = useNavigate()
  const { dealId } = useParams()
  const dispatch = useDispatch()
  const wireInstructions = useSelector(getWireInstructions)
  const [isInstructionsLoaded, setIsInstructionsLoaded] = useState(false)
  const deals: DealParams[] = useSelector(getDeals)
  const [deal, setDeal] = useState<DealParams>()

  useEffect(() => {
    dispatch(loadWireInstructions())
  }, [])

  useEffect(() => {
    setDeal(deals?.find(f => f.id === dealId))
  }, [deals])

  const groupByEntity = wireInstructions?.reduce(
    (result: Entity, wireInstruction: WireInstructionParams) => {
      const entityId = wireInstruction.entity.id
      if (!result[entityId]) {
        result[entityId] = {
          id: entityId.replace(/"/g, ''),
          entityName: wireInstruction.entityName,
          instructions: [] as WireInstructionParams[],
        }
      }
      result[entityId].instructions.push(wireInstruction)
      return result
    },
    {}
  )

  const groupedInstructions = Object.values(groupByEntity)

  const filteredInstructions: WireInstruction[] = groupedInstructions.filter(
    group =>
      deal?.borrowers.some(
        (borrower: { id: string }) => borrower.id === group.id
      )
  ) as WireInstruction[]

  useEffect(() => {
    if (!isInstructionsLoaded && filteredInstructions.length > 0) {
      setIsInstructionsLoaded(true)
    }
  }, [filteredInstructions])

  const items = [
    { title: 'Deal Management', action: () => navigate('/dealmanagement') },
    {
      title: deal?.dealName,
      action: () => navigate(`/dealmanagement/deal/${dealId}/${deal?.status}`),
    }
  ].map((item, index) => (
    <Anchor
      onClick={() => item.action()}
      key={index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  return (
    <PageLayout>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      <PageTitle pageTitle="Guarantors" />
      <TableList
        data={filteredInstructions}
        renderSectionTitle={(entity) => (
          <SectionTitle>
            {entity.entityName} ({entity.instructions.length} Wire Instructions)
          </SectionTitle>
        )}
        renderItem={(entity) =>
          entity.instructions.map((instruction: WireInstructionParams) => (
            <Stack w="100%">
              <Stack w="100%" className="sectionDetailBody">
                <Text className="sectionDetailHeader">{getCurrencyDescription(instruction.currency)}</Text>
                <MainTable
                  tableName="Details"
                  csvExportEnabled={true}
                  columnDefs={getColumnDefs(instruction.currency)}
                  data={cleanBorrowers(instruction)}
                  enableTopToolbar={false}
                  minHeight="80px"
                  headerBackgroundColor="#F0EEEE"
                  withPagination={true}
                  setRow={() => { }}
                />
              </Stack>
            </Stack>
          ))
        }
      />
    </PageLayout>
  )
}
