import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { formatDateToShow, stringToDate } from 'app/utils/util-functions'



function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const colsFacilityFees: MRT_ColumnDef<{ startDate?: string, endDate?: string, amount?: string, paidDate?: string, status?: string }>[] = [
  {
    size: 20,
    accessorKey: 'startDate',
    header: 'Start Date',
    Header: <TableHead header={'Start Date'} />,
    Cell: ({ cell }) => <TableRowText text={formatDateToShow(cell.getValue<string>() ? stringToDate(cell.getValue<string>()) : undefined)} />,
  },
  {
    size: 20,
    accessorKey: 'endDate',
    header: 'End Date',
    Header: <TableHead header={'End Date'} />,
    Cell: ({ cell }) => <TableRowText text={formatDateToShow(cell.getValue<string>() ? stringToDate(cell.getValue<string>()) : undefined)} />,
  },
  {
    size: 20,
    accessorKey: 'amount',
    header: 'Amount',
    Header: <TableHead header={'Amount'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 20,
    accessorKey: 'paidDate',
    header: 'Paid Date',
    Header: <TableHead header={'Paid Date'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 20,
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  }
]