import { MRT_ColumnDef } from 'mantine-react-table'
import { Avatar, Text, Tooltip } from '@mantine/core'
import { ReactNode } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import getInitials from 'app/views/components/functions/getInitials'
import { EntityParams } from 'app/models/entity-params'
import { formatDateToShow, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import FacilityActions from '../facility-details/facility-actions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export default function getLockedColumnDefs(
  entities: EntityParams[],
): MRT_ColumnDef<FacilityParams>[] {
  return [
    {
      size: 40,
      accessorKey: 'dealName',
      header: 'Deal Name',
      Header: <TableHead header={'Deal Name'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'name',
      header: 'Name',
      Header: <TableHead header={'Name'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'facilityType',
      header: 'Facility Type',
      Header: <TableHead header={'Facility Type'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'amendmentDate',
      header: 'Termination Date',
      Header: <TableHead header={'Termination Date'} />,
      Cell: ({ cell }) => {
        return <Text fz="1.5em"> {formatDateToShow(cell.getValue<Date>())}</Text>
      },
    },
    {
      size: 50,
      accessorKey: 'amount',
      header: 'Amount',
      Header: <TableHead header={'Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell, row }: any) => (
        <TableRowText
          text={formatNumberToCurrency(cell.getValue(), row.original.baseCurrency ?? 'USD')}
        />
      ),
    },
    {
      size: 50,
      accessorKey: 'startDate',
      header: 'Start Date',
      Header: <TableHead header={'Start Date'} />,
      accessorFn: row => {
        return stringToDate(row.startDate);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{formatDateToShow(cell.getValue<Date>())}</Text>
      ), //render Date as a string
    },
    {
      size: 50,
      accessorKey: 'maturity',
      header: 'Maturity Date',
      Header: <TableHead header={'Maturity Date'} />,
      accessorFn: row => {
        return stringToDate(row.maturity);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{formatDateToShow(cell.getValue<Date>())}</Text>
      ), //render Date as a string
    },
    {
      size: 50,
      accessorFn: row => {
        const entityName = row?.adminEntity?.id
          ? entities.find(entity => entity.id === cleanUUID(row?.adminEntity?.id))
            ?.entityName ?? ''
          : null
        return entityName;
      },
      accessorKey: 'adminEntity',
      header: 'Admin',
      Header: <TableHead header={'Admin'} />,
      Cell: ({ cell }) => {
        const entityName = cell.getValue<string>() ?? ''
        return (
          <Tooltip label={entityName}>
            <Avatar radius="xl" color="cyan" size="lg">
              {getInitials(entityName)}
            </Avatar>
          </Tooltip>
        )
      },
    },
    {
      size: 40,
      accessorKey: 'id',
      header: 'Actions',
      Header: <TableHead header={'Actions'} />,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: cell => <FacilityActions row={cell.row.original} />,
    },
  ]
}
