import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { DealFeeParams, DealParams } from 'app/models/deal-params'
import { ReactNode } from 'react'
import { frequencyOptions } from 'app/models/dropdown-options'
import { formatDateToShow, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function getColumnDefs(
  data: DealParams
): MRT_ColumnDef<DealFeeParams>[] {
  const amount = Number(data?.amount) ?? 0

  const currency = data
    ? (data as any).currency ?? (data as any).baseCurrency ?? 'USD'
    : 'USD'

  return [
    {
      size: 40,
      accessorKey: 'feeType',
      header: 'Fee Type',
      Header: <TableHead header={'Fee Type'} />,
      Cell: ({ cell }) => {
        return (
          <Text>
            {cell.getValue<string>()
              ?.toString()
              .replace(/([A-Z])/g, ' $1')
              .trim()}
          </Text>
        )
      },
    },
    {
      size: 40,
      accessorKey: 'feeRate',
      header: 'Amount',
      Header: <TableHead header={'Amount'} />,
      Cell: ({ cell }: any) => (
        <TableRowText
          text={formatNumberToCurrency(((Number(cell.getValue()) / 100) * amount), currency)}
        />
      ),
    },
    {
      size: 40,
      accessorKey: 'frequency',
      header: 'Frequency',
      Header: <TableHead header={'Frequency'} />,
      Cell: ({ cell }) => {
        return (
          <Text>
            {
              frequencyOptions.find((e: any) => e.value === cell.getValue<string>())
                ?.label
            }
          </Text>
        )
      },
    },
    {
      size: 40,
      accessorKey: 'firstPaymentDate',
      header: 'First Payment Date',
      Header: <TableHead header={'First Payment Date'} />,
      Cell: ({ cell }) => {
        return <Text>{formatDateToShow(cell.getValue<string>() ? stringToDate(cell.getValue<string>()) : undefined)}</Text>
      },
    },
  ]
}
