import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { formatDateToShow, stringToDate } from 'app/utils/util-functions'
import { CleanedFacilityHistoryData } from 'app/models/facility-params'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function getColumnDefs(): MRT_ColumnDef<CleanedFacilityHistoryData>[] {

  return [
    {
      size: 50,
      accessorKey: 'Type',
      header: 'Type',
      Header: <TableHead header={'Type'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Start Date',
      header: 'Start Date',
      Header: <TableHead header={'Start Date'} />,
      accessorFn: row => {
        return stringToDate(row['Start Date']);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{formatDateToShow(cell.getValue<Date>())}</Text>
      ),
    },
    {
      size: 50,
      accessorKey: 'End Date',
      header: 'End Date',
      Header: <TableHead header={'End Date'} />,
      accessorFn: row => {
        return stringToDate(row['End Date']);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{formatDateToShow(cell.getValue<Date>())}</Text>
      ),
    },
    {
      size: 50,
      accessorKey: 'Amount',
      header: 'Amount',
      Header: <TableHead header={'Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Days',
      header: 'Days',
      Header: <TableHead header={'Days'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'History Date',
      header: 'History Date',
      Header: <TableHead header={'History Date'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'History Time',
      header: 'History Time',
      Header: <TableHead header={'History Time'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
  ]
}