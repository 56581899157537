import { Stack, Button, Table, Text } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'
import { LocActionType } from 'app/models/dropdown-options'
import ExportToCsv from 'app/views/components/ExportToCsv/ExportToCsv'
import { formatDateToShow, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { getLocHistory } from 'app/state/ducks/letters-of-credits/selectors'
import { loadLocHistory } from 'app/state/ducks/letters-of-credits/thunks'

type Props = {
  close: any
  locId: string
}

export default function LocHistoryForm({ close, locId }: Props) {
  const locHistory = useSelector(getLocHistory)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadLocHistory(locId))
  }, [locId])

  const locArrayObjects = function () {
    const exportObjVals = locHistory.map((item, index) => {
      const exportObj: any = {}
      const endDate = locHistory[index + 1]
        ? '' + locHistory[index + 1].effectiveDate
        : item.loc.initialExpirationDate
      exportObj['Start Date'] = formatDateToShow(item?.effectiveDate ? stringToDate(item.effectiveDate) : undefined)
      exportObj['End Date'] = formatDateToShow(endDate ? stringToDate(endDate) : undefined)
      exportObj['Amount'] = formatNumberToCurrency(Number(item.loc.amount), item.loc?.currency ?? 'USD')
      exportObj['Days'] = moment(exportObj['End Date']).diff(moment(exportObj['Start Date']), 'days')
      exportObj['Transaction'] = LocActionType.find(
        option => option.value == item.actionType
      )?.label ?? item.actionType
      return exportObj
    })
    return exportObjVals
  }

  return (
    <Stack w="100%">
      <div
        style={{
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Letter of Credit History
      </div>
      <div
        style={{
          border: '1px solid #D1D5DB',
          backgroundColor: '#32439A',
          fontWeight: 'bold',
        }}
      >
        <Table>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>
                <Text color="white">Start Date</Text>
              </th>
              <th style={{ width: '10%' }}>
                <Text color="white">End Date</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Amount</Text>
              </th>
              <th style={{ width: '10%' }}>
                <Text color="white">Days</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Transaction</Text>
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: '#E5E7EB' }}>
            {locArrayObjects() && locArrayObjects().length > 0 &&
              locArrayObjects().map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{ border: '1px solid #D1D5DB', height: '3vw' }}
                  >
                    <td>
                      <Text>{item['Start Date']}</Text>
                    </td>
                    <td>
                      <Text>{item['End Date']}</Text>
                    </td>
                    <td>
                      <Text>
                        {item['Amount']}
                      </Text>
                    </td>
                    <td>
                      <Text>
                        {item['Days']}
                      </Text>
                    </td>
                    <td>
                      <Text>{item['Transaction']}</Text>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '70px',
        }}
      >
        <ExportToCsv csvName='Letter of Credit History' exportObj={locArrayObjects()} style={{
          width: '130px',
          backgroundColor: '#A4CAFE',
          color: 'black',
          fontWeight: 'bold',
          borderRadius: '8px',
        }} />

        <Button
          onClick={close}
          style={{
            width: '130px',
            backgroundColor: '#32439A',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: '8px',
          }}
        >
          Exit
        </Button>
      </div>
    </Stack>
  )
}
