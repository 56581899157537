import { Avatar, Group, Tooltip, Anchor } from '@mantine/core';
import { formatDateToShow, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions';
import cleanUUID from 'app/views/components/functions/cleanUUID';
import getInitials from 'app/views/components/functions/getInitials';
import { EntityParams } from 'app/models/entity-params';
import { LetterOfCreditParams } from 'app/models/letter-of-credit';
import { LetterOfCreditSubtype, LetterOfCreditType } from 'app/models/dropdown-options';


interface LoanDetailsProps {
    row: LetterOfCreditParams;
    entities: EntityParams[];
}

export const getLoanDetailsObjectArray = ({
    row,
    entities,
}: LoanDetailsProps) => {
    const entityIdToName = new Map<string, string>();
    if (Array.isArray(entities)) {
        for (const entity of entities) {
            if (entity.id) {
                entityIdToName.set(entity.id, entity.entityName);
            }
        }
    }

    return [
        {
            label: 'LC Type',
            value: LetterOfCreditType.find((option: { value: string; }) => option.value === row.letterOfCreditType)?.label ?? 'N/A',
        },
        {
            label: 'LC Sub Type',
            value: LetterOfCreditSubtype.find((option: { value: string; }) => option.value === row.letterOfCreditStandbyType)?.label ?? 'N/A',
        },
        {
            label: 'Borrower',
            value: (
                <Group>
                    <Tooltip.Group openDelay={300} closeDelay={100}>
                        <Avatar.Group spacing="sm">
                            {row?.borrowers?.length > 0
                                ? row.borrowers.map((borrower: { id: string }) => (
                                    <Anchor key={`row-borrower-${borrower.id}`}>
                                        <Tooltip
                                            label={entityIdToName.get(cleanUUID(borrower.id)) || 'Unknown'}
                                            withArrow
                                        >
                                            <Avatar color="violet" radius="xl">
                                                {getInitials(entityIdToName.get(cleanUUID(borrower.id)) || 'Unknown')}
                                            </Avatar>
                                        </Tooltip>
                                    </Anchor>
                                ))
                                : null}
                        </Avatar.Group>
                    </Tooltip.Group>
                </Group>
            ),
        },
        {
            label: 'Issuing Bank',
            value: (
                <Group>
                    <Tooltip.Group openDelay={300} closeDelay={100}>
                        <Avatar.Group spacing="sm">
                            {row?.issuingBanks?.length > 0
                                ? row.issuingBanks.map((issuingBank: { id: string }) => (
                                    <Anchor key={`row-borrower-${issuingBank.id}`}>
                                        <Tooltip
                                            label={entityIdToName.get(cleanUUID(issuingBank.id)) || 'Unknown'}
                                            withArrow
                                        >
                                            <Avatar color="violet" radius="xl">
                                                {getInitials(entityIdToName.get(cleanUUID(issuingBank.id)) || 'Unknown')}
                                            </Avatar>
                                        </Tooltip>
                                    </Anchor>
                                ))
                                : null}
                        </Avatar.Group>
                    </Tooltip.Group>
                </Group>
            ),
        },
        {
            label: 'Beneficiaries',
            value: (
                <Group>
                    <Tooltip.Group openDelay={300} closeDelay={100}>
                        <Avatar.Group spacing="sm">
                            {row?.beneficiaries?.length > 0
                                ? row.beneficiaries.map((beneficiarie: { id: string }) => (
                                    <Anchor key={`row-borrower-${beneficiarie.id}`}>
                                        <Tooltip
                                            label={entityIdToName.get(cleanUUID(beneficiarie.id)) || 'Unknown'}
                                            withArrow
                                        >
                                            <Avatar color="violet" radius="xl">
                                                {getInitials(entityIdToName.get(cleanUUID(beneficiarie.id)) || 'Unknown')}
                                            </Avatar>
                                        </Tooltip>
                                    </Anchor>
                                ))
                                : null}
                        </Avatar.Group>
                    </Tooltip.Group>
                </Group>
            ),
        },
        { label: 'Currency', value: row?.currency ?? 'N/A' },
        {
            label: 'Amount',
            value: formatNumberToCurrency(Number(row?.amount) ?? 0, row?.currency ?? 'USD'),
        },
        {
            label: 'Issuance Fee Rate',
            value: row.issuingFeeRate ?? '',
        },
        {
            label: 'Effective Date',
            value: formatDateToShow(row?.effectiveDate ? stringToDate(row.effectiveDate) : undefined),
        },
        {
            label: 'Expiration Date',
            value: formatDateToShow(row?.initialExpirationDate ? stringToDate(row.initialExpirationDate) : undefined),
        },
        {
            label: 'Final Expiration Date',
            value: formatDateToShow(row?.adjustedExpirationDate ? stringToDate(row.adjustedExpirationDate) : undefined),
        },
        {
            label: 'LC ID',
            value: row?.systemId ?? '',
        },
        {
            label: 'FX Rate',
            value: row?.fxRate ?? '',
        },
        {
            label: 'Notification Period',
            value: row?.autoExtendNotificationPeriod,
        },
        {
            label: 'Auto Extend Period',
            value: row?.autoExtendPeriod,
        },
    ]
};
