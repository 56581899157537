import { Group, Accordion, Text, Stack } from '@mantine/core'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import { formatDateToShow, stringToDate } from 'app/utils/util-functions'

interface Props {
  calendar?: HolidayCalendarParams | null
}

export function HolidayCalendarSummaryAccordion({ calendar }: Props) {

  return (
    <Accordion.Item value="HolidayCalendarSummary">
      <Accordion.Control fw={600} fz="14px" p="lg">
        Summary
      </Accordion.Control>
      <Accordion.Panel>
        <Stack w='100%'>
          <Group position="apart">
            <Text>{calendar?.name}</Text>
          </Group>
          {calendar && calendar.holidayInfo ?
            <Stack w='100%'>
              {calendar.holidayInfo.map((holiday, i) => {
                return (
                  <Group w='100%' noWrap position="apart" key={i + ' holidaycalendarAcordion'}>
                    <Text
                      fz="14px"
                      fw={800}
                      sx={{
                        fontFamily: 'Plus Jakarta Sans',
                      }}
                    >
                      {holiday.holidayName}
                    </Text>

                    <Text>{formatDateToShow(holiday?.holidayDate ? stringToDate(holiday.holidayDate) : undefined)}</Text>
                  </Group>
                )
              })}

            </Stack>
            : null}
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
