import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getFacilities,
  addFacility,
  editFacility,
  getFacility,
  submitFacility,
  rejectFacility,
  approveFacility,
  deleteFacility,
  getFacilityHistory,
  getLockedFacilities,
  startLoadingFacilities,
  stopLoadingFacilities,
} from 'app/state/ducks/facilities/actions'
import { DraftFacilityParams, FacilityParams } from 'app/models/facility-params'

export const saveFacility =
  (savedFacility: DraftFacilityParams, facility?: any) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const updated_savedFacility: DraftFacilityParams = {
      ...savedFacility,
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }
    if (facility) {
      return client
        .put(`${apiServerUrl}/api/draft-facilities`, updated_savedFacility)
        .then(facility => {
          dispatch(editFacility(facility.data))
          return { payload: facility.data, success: true }
        })
        .catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/draft-facilities`, updated_savedFacility)
        .then(facility => {
          dispatch(addFacility(facility.data))
          return { payload: facility.data, success: true }
        })
        .catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    }
  }

export const loadFacilities = (deal: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(`${apiServerUrl}/api/facilities/deal?id=${deal.id}`)
    .then(facilities => {
      return dispatch(getFacilities(facilities.data))
    })
    .catch(e => {
      return { payload: e.response.data.error, success: false }
    })
}

const loadApprovedFacilities = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/approved-facilities/`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadDraftFacilities = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/draft-facilities`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadSubmittedFacilities = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/submitted-facilities`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllFacilities = () => async (dispatch: Dispatch) => {
  try {
    dispatch(startLoadingFacilities())
    const [draftFacilities, submittedFacilities, facilities] =
      await Promise.all([
        loadDraftFacilities(),
        loadSubmittedFacilities(),
        loadApprovedFacilities(),
      ])

    const subFacilities = submittedFacilities.map((a: { facility: any }) => ({
      ...a.facility,
      status: 'Submitted',
    }))

    const allFacilities = draftFacilities
      .map((loan: any) => ({ ...loan, status: 'Draft' }))
      .concat(subFacilities)
      .concat(facilities.map((loan: any) => ({ ...loan, status: 'Approved' })))

    return dispatch(getFacilities(allFacilities))
  } catch (error) {
    console.log('THUNK ERROR: ', error)
  } finally {
    dispatch(stopLoadingFacilities())
  }
}

export const setOneFacility = (facility: any) => async (dispatch: Dispatch) => {
  dispatch(getFacility({ ...facility }))
}

export const setAllFacilities =
  (allFacilities: any) => async (dispatch: Dispatch) => {
    dispatch(getFacilities(allFacilities))
  }

export const loadOneFacility =
  (facilityId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower =
      status.toLowerCase() == 'terminated' ? 'locked' : status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-facilities/one?id=${facilityId}`)
      .then(facilities => {
        if (statusLower === 'submitted' || statusLower === 'locked') {
          return dispatch(
            getFacility({ ...facilities.data.facility, status: status }),
          )
        }
        return dispatch(getFacility({ ...facilities.data, status: status }))
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const submitForApproval =
  (facility: FacilityParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-facilities/submit`, { id: facility.id })
      .then(response => {
        dispatch(submitFacility(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const rejectSubmittedFacility =
  (facility: FacilityParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-facilities/reject`, {
        id: facility.id,
      })
      .then(response => {
        dispatch(rejectFacility(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const approveSubmittedFacility =
  (facility: FacilityParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-facilities/approve`, {
        id: facility.id,
      })
      .then(response => {
        dispatch(approveFacility(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const deleteDraftFacility =
  (ids: string[]) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/draft-facilities?ids=${ids}`)
      .then(response => {
        return dispatch(deleteFacility(response.data))
      })
      .catch(e => {
        console.log('THUNK ERROR', e)
      })
  }

export const loadFacilityHistory =
  (facilityId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/approved-facilities/history?id=${facilityId}`)
      .then(response => {
        return dispatch(getFacilityHistory(response.data))
      })
      .catch(error => {
        console.log('THUNK ERROR: ', error)
      })
  }

export const loadLockedFacilities = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(`${apiServerUrl}/api/locked-facilities`)
    .then(response => {
      const lockedFacility = response.data.map(
        (a: { facility: any; locked: string }) => ({
          ...a.facility,
          status: 'Terminated',
          terminationDate: a.locked,
        }),
      )
      return dispatch(getLockedFacilities(lockedFacility))
    })
    .catch(error => {
      console.log('THUNK ERROR: ', error)
    })
}

export const loadFacilitySnapshot = (facilityId: string) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(`${apiServerUrl}/api/approved-facilities/snapshot?id=${facilityId}`)
    .then(facility => {
      return { success: true, payload: facility.data }
    })
    .catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}


export const submitBulkFacilities =
  (facilities: FacilityParams[]) => async () => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-facilities/bulk`, { facilityList: facilities })
      .then(response => {
        return { success: true, payload: response.data }
      })
      .catch(e => {
        console.log('facilities bulk upload error', e)
        return { success: false, payload: e.response.data.error }
      })
  }