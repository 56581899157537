import React from 'react';
import { Stack, Group, Text, Radio } from '@mantine/core';
import { TradeEntryEntitySearch } from './trade-entry-entity-search';
import { useSelector } from 'react-redux';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { TextLabelInput } from 'app/views/components/inputs/TextLabelInput';

interface Props {
  title: string;
  onBuyerSellerChange?: (value: any) => void;
  form: any
  isMyEntityBlock: boolean
  loading: boolean
}

export function TradeEntryCounterParty({ title, onBuyerSellerChange, form, isMyEntityBlock, loading }: Props) {
  const entities = useSelector(getEntities)

  const checkIfEntityIsBuyer = () => {
    return (isMyEntityBlock && form.values.myEntity === 'Buy') || (!isMyEntityBlock && form.values.myEntity === 'Sell')
  }

  const onChange = (value?: string | null) => {
    if (!value) {
      form.setFieldValue(checkIfEntityIsBuyer() ? 'buyerId' : 'sellerId', '')
      form.setFieldValue(checkIfEntityIsBuyer() ? 'buyer' : 'seller', null)
    }
    const entity = entities.find((e) => e.id === value)
    form.setFieldValue(checkIfEntityIsBuyer() ? 'buyerId' : 'sellerId', value)
    form.setFieldValue(checkIfEntityIsBuyer() ? 'buyer' : 'seller', entity)
  }
  return (
    <Stack className="tradeEntryCounterpartiesSection">
      <Text w="25%" className="tradeEntryCounterpartiesTitle">
        {title}
      </Text>
      <Stack className="tradeEntryDealSectionStack"
      // style={{ border: "1px solid #B4B3B3", background: "#ede6e6", padding: "20px" }}
      >
        <Group noWrap className="tradeEntryCounterpartiesTitleSection">
          {isMyEntityBlock && (
            <Radio.Group name="tradeEntryRadioButtons" onChange={onBuyerSellerChange} value={form.values.myEntity}>
              <Group noWrap>
                <Radio
                  disabled={loading}
                  pt="10px"
                  size="lg"
                  className="tradeEntryRadioButtons"
                  labelPosition="left"
                  label="Buy"
                  value="Buy"
                />
                <Radio
                  disabled={loading}
                  pt="10px"
                  size="lg"
                  className="tradeEntryRadioButtons"
                  labelPosition="left"
                  label="Sell"
                  value="Sell"
                />
              </Group>
            </Radio.Group>
          )}
          <TradeEntryEntitySearch loading={loading} onChange={onChange} value={checkIfEntityIsBuyer() ? form.values.buyerId : form.values.sellerId} isRight={!isMyEntityBlock} />

        </Group>
        <Stack className="tradeEntryCounterpartiesStack">
          <Group noWrap w="100%" className="dataEntrySubSection">
            <TextLabelInput label="Entity Name" > <Text className='newInput'>{checkIfEntityIsBuyer() ? form.values.buyer?.entityName ?? '' : form.values.seller?.entityName ?? ''}</Text>
            </TextLabelInput>
          </Group>
          <Group noWrap className="tradeEntryCounterpartiesSubSections">
            <Group noWrap w="50%" className="dataEntrySubSection">
              <TextLabelInput label="Entity Type" > <Text className='newInput'>{checkIfEntityIsBuyer() ? form.values.buyer?.entityTypeName ?? '' : form.values.seller?.entityTypeName ?? ''}</Text>
              </TextLabelInput>
            </Group>
            <Group noWrap w="50%" className="dataEntrySubSection">
              <TextLabelInput label="MEI" > <Text className='newInput' >{checkIfEntityIsBuyer() ? form.values.buyer?.mei ?? '' : form.values.seller?.mei ?? ''}</Text>
              </TextLabelInput>
            </Group>
          </Group>
          <Group noWrap className="tradeEntryCounterpartiesSubSections">
            <Group noWrap w="50%" className="dataEntrySubSection">
              <TextLabelInput label="Internal ID" > <Text className='newInput'>{checkIfEntityIsBuyer() ? form.values.buyer?.internalId ?? '' : form.values.seller?.internalId ?? ''}</Text>
              </TextLabelInput>
            </Group>
            <Group noWrap w="50%" className="dataEntrySubSection">

              <TextLabelInput label="LendOS ID" > <Text className='newInput'>{checkIfEntityIsBuyer() ? form.values.buyer?.systemId ?? '' : form.values.seller?.systemId ?? ''}</Text>
              </TextLabelInput>
            </Group>
          </Group>
        </Stack>
      </Stack>
    </Stack>
  );
}
