import { Menu, Modal } from '@mantine/core'
import { DealsModal } from 'app/views/pages/deals-management/deals/deals-modal'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import MultiPartForm from 'app/views/pages/accounts-management/entities/multipart-form-modal'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { UpdatedFacilitiesScreen } from 'app/views/pages/deals-management/facilities/updated-facilities-screen'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { loadCalendars } from 'app/state/ducks/holiday-calendars/thunks'
import { loadAllEntityProfiles } from 'app/state/ducks/entity-profile/thunks'
import { loadAllEntityTypes } from 'app/state/ducks/entity-type/thunks'
import { useNavigate } from 'react-router-dom'
import PrimaryInput from '../../buttons/PrimaryInput'
import IonIcon from '@reacticons/ionicons'

export function CreateNewButton() {
  const navigate = useNavigate()

  const [openDeal, setOpenDeal] = useState(false)
  const [openFacility, setOpenFacility] = useState(false)
  const [openEntity, setOpenEntity] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAllDeals())
    dispatch(loadAllFacilities())
    dispatch(loadEntities())
    dispatch(loadContacts())
    dispatch(loadCalendars())
    dispatch(loadWireInstructions())
    dispatch(loadIndexRateOptions())
    dispatch(loadAllEntityProfiles())
    dispatch(loadAllEntityTypes())
  }, [])

  return (
    <>
      <Menu shadow="md" width={200} position="bottom-start">
        <Menu.Target>

          <div style={{ width: 'fit-content' }}>
            <PrimaryInput w='159px'>
              Create New
              <IonIcon name='chevron-down-outline' className='primaryInputChevronDown' />
            </PrimaryInput>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => setOpenDeal(true)}> Deal </Menu.Item>
          <Menu.Item onClick={() => setOpenFacility(true)}>Facility</Menu.Item>
          <Menu.Item onClick={() => setOpenEntity(true)}> Entity</Menu.Item>
          <Menu.Item onClick={() => {
            navigate('/tradeentry/create', { state: { tab: 'tradeEntryCreation' } });
          }}
          > Trade</Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <DealsModal open={openDeal} setOpen={setOpenDeal} useStepper={true} />

      <MultiPartForm setOpen={setOpenEntity} open={openEntity} />

      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openFacility}
        onClose={() => setOpenFacility(false)}
        centered={true}
        size="77%"
      >
        <UpdatedFacilitiesScreen
          deal={undefined}
          facility={undefined}
          onClose={() => setOpenFacility(false)}
        />
      </Modal>
    </>
  )
}