import { MasterTradeParams } from "app/models/master-trade-params";

export type ExportTradeDataParams = {
    status: string;
    tradeId: string;
    internalId: string;
    tradeDetails: string;
    transferType: string;
    tradeAction: string;
    tradeDate: string;
    myEntity: string;
    mei: string;
    counterparty: string;
    counterpartyMei: string;
    deal: string;
    dealCusip: string;
    facilities: string;
    tradedAmount: string;
    currentAmount: string;
    price: string;
}

export const transformTradeDataForExport = (masterTrades: MasterTradeParams[]) => {
    const tradeData: ExportTradeDataParams[] = [];

    masterTrades.forEach(trade => {
        // fallback in case tradedFacilities is empty
        const facilities = trade.tradedFacilities.length > 0
            ? trade.tradedFacilities
            : [{ facilityDetails: { name: '' }, tradeAmount: '0', currentAmount: '0', price: '' }];
        // note: this will show multiple rows for a single master trade if it has multiple facilities
        facilities.forEach(facility => {
            tradeData.push({
                status: trade.status || '',
                tradeId: trade.id || '',
                internalId: trade.clientId || '',
                tradeDetails: trade.tradeDetails || '',
                transferType: trade.transferType || '',
                tradeAction: trade.myEntity || '',
                tradeDate: trade.tradeDate || '',
                myEntity: trade.entityName || '',
                mei: trade.entityMei || '',
                counterparty: trade.counterpartyName || '',
                counterpartyMei: trade.counterpartyMei || '',
                deal: trade.dealName || '',
                dealCusip: trade.dealCusip || '',
                facilities: facility.facilityDetails?.name || '',
                tradedAmount: facility.tradeAmount || '', 
                currentAmount: facility.currentAmount || '',
                price: facility.price || ''
            });
        });
    });

    return tradeData;
}
