import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, } from '@mantine/core'
import { ReactNode } from 'react'
import { MasterTradeParams } from 'app/models/master-trade-params'

function TableHead({ header }: { header: string }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '24px',
                color: '#413E3E',
            }}
        >
            {header}
        </Text>
    )
}

function TableRowText({ text }: { text: ReactNode }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '19px',
                color: '#413E3E',
            }}
        >
            {text}
        </Text>
    )
}


export const columnDefs: MRT_ColumnDef<MasterTradeParams>[] = [

    {
        size: 50,
        accessorKey: 'status',
        header: 'Status',
        Header: <TableHead header={'Status'} />,
        Cell: ({ cell }) => {
            const status = (cell.getValue() as string ?? '').toLowerCase();
            const statusColors: { [key: string]: string } = {
                open: '#287A26',
                settled: '#EE5959',
                draft: '#757373'
            };
            return (

                <Text style={{
                    color: statusColors[status] || '#000000',
                    textTransform: 'capitalize',
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '19px',
                }}>{cell.getValue() as string ?? ''}</Text>
            );
        },
    },
    {
        size: 80,
        header: 'Cell 1',
        Header: () => (
            <div>
                <TableHead header={'Trade ID'} />
                <TableHead header={'Internal ID'} />
            </div>
        ), Cell: ({ row }) => {
            const tradeId = row.original.tradeId;
            const clientId = row.original.clientId;
            return (
                <div>
                    <Text style={{
                        color: '#476DB9',
                        textTransform: 'capitalize',
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '19px',
                        textDecoration: 'underline',
                    }}>{tradeId}</Text>
                    <TableRowText text={`${clientId}`} />
                </div>
            );
        },
    },
    {
        size: 60,
        header: 'Cell 2',
        Header: () => (
            <div>
                <TableHead header={'Trade Details'} />
                <TableHead header={'Transfer Type'} />
            </div>
        ), Cell: ({ row }) => {
            return (
                <div>
                    <TableRowText text={row.original.tradeDetails} />
                    <TableRowText text={row.original.transferType} />
                </div>
            );
        },
    },
    {
        size: 60,
        header: 'Cell 3',
        Header: () => (
            <div>
                <TableHead header={'Trade Action'} />
                <TableHead header={'Trade Date'} />
            </div>
        ), Cell: ({ row }) => {
            return (
                <div>
                    <TableRowText text={`${row.original.myEntity}`} />
                    <TableRowText text={`${row.original.tradeDate}`} />
                </div>
            );
        },
    },
    {
        size: 60,
        header: 'Cell 4',
        Header: () => (
            <div>
                <TableHead header={'My Entity'} />
                <TableHead header={'MEI'} />
            </div>
        ), Cell: ({ row }) => {
            return (
                <div>
                    <Text style={{
                        color: '#476DB9',
                        textTransform: 'capitalize',
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '19px',
                    }}>{row.original.entityName}</Text>
                    <TableRowText text={`${row.original.entityMei}`} />
                </div>
            );
        },
    },
    {
        size: 60,
        header: 'Cell 5',
        Header: () => (
            <div>
                <TableHead header={'Counterparty'} />
                <TableHead header={'MEI'} />
            </div>
        ), Cell: ({ row }) => {
            return (
                <div>
                    <Text style={{
                        color: '#476DB9',
                        textTransform: 'capitalize',
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '19px',
                    }}>{row.original.counterpartyName}</Text>
                    <TableRowText text={`${row.original.counterpartyMei}`} />
                </div>
            );
        },
    },
    {
        size: 60,
        header: 'Cell 6',
        Header: () => (
            <div>
                <TableHead header={'Deal (CUSIP)'} />
                <TableHead header={'Facilities (CUSIP)'} />
            </div>
        ),
        Cell: ({ row }) => {
            const tradedFacilities = row.original.tradedFacilities || [];

            let facilityCusipText = 'N/A';
            let facilityNameText = 'N/A';
            let facilityColor = '##413E3E';
            let countColor = '#476DB9';

            const refinedFacilities = tradedFacilities
                .map(facility => facility.facilityDetails)
                .filter(facility => facility !== null);

            if (refinedFacilities.length === 1) {
                facilityCusipText = refinedFacilities[0]?.cusip || 'N/A';
                facilityNameText = refinedFacilities[0]?.name || 'N/A';
            } else if (refinedFacilities.length > 1) {
                facilityCusipText = 'Multiple Facilities';
                facilityColor = '#9567AF';
                countColor = '#EE5959';
                facilityNameText = ''
            }

            return (
                <div>
                    <Text
                        style={{
                            color: '#476DB9',
                            textTransform: 'capitalize',
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '19px',
                        }}
                    >
                        {row.original.dealName + ' / ' + row.original.dealCusip}
                    </Text>
                    <Text
                        style={{
                            color: facilityColor,
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '19px',
                        }}
                    >
                        {facilityCusipText + ' ' + facilityNameText}{' '}
                        {refinedFacilities.length > 1 && (
                            <span
                                style={{
                                    color: countColor,
                                    fontWeight: 700,
                                }}
                            >
                                ({refinedFacilities.length})
                            </span>
                        )}
                    </Text>
                </div>
            );
        },
    },


    {
        size: 30,
        header: 'Cell 7',
        Header: () => (
            <div>
                <TableHead header={'Traded Amount'} />
            </div>
        ), Cell: ({ row }) => {
            return (
                <div>
                    <TableRowText text={`${row.original.tradedAmount}`} />
                </div>
            );
        },
    },

    {
        size: 30,
        header: 'Cell 8',
        Header: () => (
            <div>
                <TableHead header={'Current Amount'} />
            </div>
        ), Cell: ({ row }) => {
            return (
                <div>
                    <TableRowText text={`${row.original.currentAmount}`} />
                </div>
            );
        },
    },
    {
        size: 30,
        header: 'Cell 9',
        Header: () => (
            <div>
                <TableHead header={'Price'} />
            </div>
        ),
        Cell: ({ row }) => {
            const uniquePrices = Array.from(new Set(row.original.tradedFacilities.map(facility => facility.price)));
            const priceStyle = {
                color: uniquePrices.length === 1 ? '#413E3E' : '#9567AF',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '19px',
                fontFamily: 'Plus Jakarta Sans',
            };

            const countStyle = {
                color: '#EE5959',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '19px',
                fontFamily: 'Plus Jakarta Sans',
            };

            return (
                <div>
                    {uniquePrices.length === 1 ? (
                        <Text style={priceStyle}>
                            {uniquePrices[0]}
                        </Text>
                    ) : (
                        <Text style={priceStyle}>
                            Mult{' '}
                            <span style={countStyle}>
                                ({uniquePrices.length})
                            </span>
                        </Text>
                    )}
                </div>
            );
        },
    },
]