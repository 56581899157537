import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { formatDateToShow, formatNumberToCurrency, getDirection, stringToDate } from 'app/utils/util-functions'
import { LenderHistory } from 'app/models/lenders-position-params'
import { UpDownIndicator } from 'app/views/components/icons/UpDownIndicator'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}
export function getColumnDefs(currency: string
): MRT_ColumnDef<LenderHistory>[] {

  return [

    {
      size: 50,
      accessorKey: 'effectiveDate',
      header: 'Effective Date',
      Header: <TableHead header={'Effective Date'} />,
      accessorFn: row => {
        return stringToDate(row.effectiveDate);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'equals',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{formatDateToShow(cell.getValue<Date>())}</Text>
      ), //render Date as a string
    },

    {
      size: 50,
      accessorKey: 'transactionType',
      header: 'Transaction Type',
      Header: <TableHead header={'Transaction Type'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },

    {
      size: 50,
      accessorKey: "amount",
      header: "Amount",
      Header: <TableHead header={'Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => {
        const amount = cell.getValue<number>();
        const direction = getDirection(amount);
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UpDownIndicator direction={direction} size='1.5em' />
            <TableRowText
              text={formatNumberToCurrency(Math.abs(amount), currency)}
            />
          </div>
        );
      },
    },
    {
      size: 50,
      accessorKey: 'newBalance',
      header: 'New Balance',
      Header: <TableHead header={'New Balance'} />,
      filterVariant: 'range',
      Cell: ({ cell }: any) => (
        <TableRowText
          text={formatNumberToCurrency(cell.getValue(), currency)}
        />
      ),
    },
  ]
}
