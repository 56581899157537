import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, } from '@mantine/core'
import { ReactNode } from 'react'
import LoansActions from '../loan-actions'
import StatusPill from '../../common/pill-status'
import { formatDateToShow, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { LoanParams } from 'app/models/loan-params'
import { useSelector } from 'react-redux'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'

function TableHead({ header }: { header: string }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '19px',
                color: '#413E3E',
            }}
        >
            {header}
        </Text>
    )
}

function TableRowText({ text }: { text: ReactNode }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '19px',
                color: '#413E3E',
            }}
        >
            {text}
        </Text>
    )
}
export const columnDefs: MRT_ColumnDef<LoanParams>[] = [
    {
        size: 60,
        accessorKey: 'indexOption',
        header: 'Index Rate Option',
        Header: <TableHead header={'Index Rate Option'} />,
        Cell: ({ row }) => {
            const allIndexes = useSelector(getIndexRateOptions);
            const indexOption = allIndexes.find(index => index?.id === row.original.indexOption?.id)?.indexOption ?? '';
            return <TableRowText text={indexOption ?? ''} />;
        },
    },
    {
        size: 40,
        accessorKey: 'startDate',
        header: 'Start Date',
        Header: <TableHead header={'Start Date'} />,
        accessorFn: row => {
            return stringToDate(row.startDate);
        },
        mantineFilterTextInputProps: {
            placeholder: 'Date',
        },
        filterFn: 'lessThanOrEqualTo',
        filterVariant: 'date',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
            const formattedDate = formatDateToShow(cell.getValue<Date>());
            return <TableRowText text={formattedDate} />;
        },
    },
    {
        size: 40,
        accessorKey: 'endDate',
        header: 'End Date',
        Header: <TableHead header={'End Date'} />,
        accessorFn: row => {
            return stringToDate(row.endDate);
        },
        mantineFilterTextInputProps: {
            placeholder: 'Date',
        },
        filterFn: 'lessThanOrEqualTo',
        filterVariant: 'date',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
            const formattedDate = formatDateToShow(cell.getValue<Date>());
            return <TableRowText text={formattedDate} />;
        },
    },
    {
        size: 50,
        accessorKey: 'amount',
        header: 'Amount',
        Header: <TableHead header={'Amount'} />,
        Cell: ({ cell, row }: any) => {
            const value = cell?.getValue() ?? 0;
            const currency = row.original?.currency ?? 'USD';
            return (
                <TableRowText text={formatNumberToCurrency(value, currency)} />
            );
        },
    },
    {
        size: 40,
        accessorKey: 'allInRate',
        header: 'All In-Rate',
        Header: <TableHead header={'All In-Rate'} />,
        Cell: ({ cell }) => {
            return <TableRowText text={cell.getValue() as string ?? ''} />
        },
    },
    {
        size: 40,
        accessorKey: 'status',
        header: 'Status',
        Header: <TableHead header={'Status'} />,
        Cell: cell => {
            return (
                <StatusPill
                    state={cell.row.original.status ?? 'Draft'}
                />
            )
        },
    },
    {
        size: 40,
        accessorKey: 'id',
        header: 'Actions',
        Header: <TableHead header={'Actions'} />,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: cell => <LoansActions fromOutside={false} item={cell.row.original} />,
    },
]
