import { Avatar, Group, Tooltip, Anchor, Text } from '@mantine/core';
import { formatDateToShow, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions';
import cleanUUID from 'app/views/components/functions/cleanUUID';
import getInitials from 'app/views/components/functions/getInitials';
import { LoanParams } from 'app/models/loan-params';
import { EntityParams } from 'app/models/entity-params';
import { IndexRateOptionParams } from 'app/models/index-rate-options-params';
import { FacilityParams, IroValues } from 'app/models/facility-params';

interface LoanDetailsProps {
    row: LoanParams;
    entities: EntityParams[];
    allIndexes: IndexRateOptionParams[];
    facility: FacilityParams | undefined;
    iro: IroValues | undefined;
    currentAmountWithInterest?: { amount: number; interest: number };
    navigate: (path: string) => void;
}

export const getLoanDetailsObjectArray = ({
    row,
    entities,
    allIndexes,
    facility,
    iro,
    currentAmountWithInterest,
    navigate,
}: LoanDetailsProps) => {
    const entityIdToName = new Map<string, string>();
    if (Array.isArray(entities)) {
        for (const entity of entities) {
            if (entity.id) {
                entityIdToName.set(entity.id, entity.entityName);
            }
        }
    }

    return [
        {
            label: 'Borrower',
            value: (
                <Group>
                    <Tooltip.Group openDelay={300} closeDelay={100}>
                        <Avatar.Group spacing="sm">
                            {row?.borrower ? (
                                <Anchor key={`row-borrower-${row?.borrower?.id}`}>
                                    <Tooltip
                                        label={entityIdToName.get(cleanUUID(row?.borrower?.id)) || 'Unknown'}
                                        withArrow
                                    >
                                        <Avatar color="violet" radius="xl">
                                            {getInitials(entityIdToName.get(cleanUUID(row?.borrower?.id)) || 'Unknown')}
                                        </Avatar>
                                    </Tooltip>
                                </Anchor>
                            ) : null}
                        </Avatar.Group>
                    </Tooltip.Group>
                </Group>
            ),
        },
        {
            label: 'Amount',
            value: formatNumberToCurrency(Number(row?.amount) ?? 0, row?.currency ?? 'USD'),
        },
        {
            label: 'Start Date',
            value: formatDateToShow(row?.startDate ? stringToDate(row.startDate) : undefined),
        },
        {
            label: 'Maturity Date',
            value: formatDateToShow(row?.endDate ? stringToDate(row.endDate) : undefined),
        },
        {
            label: 'Loan ID',
            value: row?.systemId ?? '',
        },
        {
            label: 'Interest Base Rate with Rounding',
            value: row?.interestBaseRateWithRounding + '',
        },
        {
            label: 'Index Rate Option',
            value: allIndexes.find((index) => index?.id === row?.indexOption?.id)?.indexOption ?? '',
        },
        {
            label: 'Day Basis',
            value: row?.dayBasis ?? '',
        },
        {
            label: 'Interest Due at Current Date',
            value: formatNumberToCurrency(
                currentAmountWithInterest?.interest ?? 0,
                row?.currency ?? 'USD'
            ),
        },
        {
            label: 'CAS',
            value: row?.casValue + '',
        },
        {
            label: 'Interest Base Rate',
            value: row?.interestBaseRate + '',
        },
        {
            label: 'Margin',
            value: row?.margin + '',
        },
        {
            label: (
                <Text className="sectionItemTitleManual">
                    All-In Rate{' '}
                    {iro?.iroCap === row.allInRate
                        ? '(Cap)'
                        : iro?.iroFloor === row.allInRate
                            ? '(Floor)'
                            : ''}
                </Text>
            ),
            value: row?.allInRate?.toString() ?? 'N/A',
        },
        {
            label: (
                <Text
                    className="sectionItemTitleClickable"
                    onClick={() =>
                        navigate(
                            `/dealmanagement/facilities/${facility?.id}/${facility?.status}/${row?.id}/${row?.status}/interest-payment-schedule`
                        )
                    }
                    style={{ cursor: 'pointer' }}
                >
                    Interest Payment Schedule
                </Text>
            ),
            value: row?.nextInterestPaymentDate ?? '',
        },
        {
            label: 'Amount Due',
            value: formatNumberToCurrency(Number(row?.amountDue) ?? 0, row?.currency ?? 'USD'),
        },
        {
            label: 'Interest Due at Maturity',
            value: formatNumberToCurrency(
                Number(row?.accrualInterestMaturityDate) ?? 0,
                row?.currency ?? 'USD'
            ),
        },
    ];
};
