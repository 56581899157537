import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { approveSubmittedMasterTrade, rejectSubmittedMasterTrade, saveMasterTrade, submitForApproval } from 'app/state/ducks/master-trades/thunks'
import { Dispatch } from 'redux'
import { MasterTradeParams } from 'app/models/master-trade-params'

export const createDraftTrade = async (trade: MasterTradeParams, dispatch: Dispatch<any>, withMessages: boolean = false) => {
    const result: any = await dispatch(saveMasterTrade(trade, trade.id))
    if (!result.success) {
        ErrorNotification({
            title: 'Trade Entry Failed',
            message: result.payload || 'Action Failed',
        })
        return null
    }
    if (withMessages) {
        SuccessNotification({
            title: 'Trade Entry Successful',
            message: 'Trade Entry has been saved in draft mode',
        })
    }
    return result.payload
}

export const submitTrade = async (trade: MasterTradeParams, dispatch: Dispatch<any>, withMessages: boolean = false) => {
    const result: any = await dispatch(submitForApproval(trade))
    if (!result.success) {
        ErrorNotification({
            title: 'Trade Submission Failed',
            message: result.payload || 'Action Failed',
        })
        return null
    }
    if (withMessages) {
        SuccessNotification({
            title: 'Trade Submitted Successful',
            message: 'Trade Entry has been submitted for approval',
        })
    }
    return result.payload
}

export const approveTrade = async (trade: MasterTradeParams, dispatch: Dispatch<any>, withMessages: boolean = false) => {
    const result: any = await dispatch(approveSubmittedMasterTrade(trade))
    if (!result.success) {
        ErrorNotification({
            title: 'Trade Approval Failed',
            message: result.payload || 'Action Failed',
        })
        return null
    }
    if (withMessages) {
        SuccessNotification({
            title: 'Trade Approved Successful',
            message: 'Trade Entry has been Open',
        })
    }
    return result.payload
}

export const rejectTrade = async (trade: MasterTradeParams, dispatch: Dispatch<any>, withMessages: boolean = false) => {
    const result: any = await dispatch(rejectSubmittedMasterTrade(trade))
    if (!result.success) {
        ErrorNotification({
            title: 'Trade Approval Failed',
            message: result.payload || 'Action Failed',
        })
        return null
    }
    if (withMessages) {
        SuccessNotification({
            title: 'Trade Rejected Successful',
            message: 'Trade Entry has been converted to Draft',
        })
    }
    return result.payload
}