import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import { setBusinessDate, addBusinessDate } from './actions'
import {
  BusinessDateParams,
  InitialBusinessDateParams,
} from 'app/models/business-date-params'

let businessDate = new Date()

const currentDate = new Date()
const previousDate = new Date(currentDate)
previousDate.setDate(previousDate.getDate() - 1)
const dayStartTime = new Date(currentDate)
dayStartTime.setHours(9, 0, 0, 0)
const dayEndTime = new Date(currentDate)
dayEndTime.setHours(17, 0, 0, 0)

// Format dates to 'YYYY-MM-DD'
const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

const todaysBusinessDate: InitialBusinessDateParams = {
  currentDate: formatDate(currentDate),
  previousDate: formatDate(previousDate),
  lastActor: null,
  dateChangers: [],
  location: 'EST',
  dayStartTime: dayStartTime.toISOString(),
  dayEndTime: dayEndTime.toISOString(),
  calendars: [],
  observers: [],
}

const saveInitialBusinessDate =
  (savedBusinessDate: InitialBusinessDateParams) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl

    return client
      .post(`${apiServerUrl}/api/business-dates`, savedBusinessDate)
      .then(businessDate => {
        dispatch(addBusinessDate(businessDate))
        return { success: true, payload: businessDate }
      })
      .catch(error => {
        return { success: false, payload: error.response }
      })
  }

export const loadBusinessDate = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/business-dates/`)
    if (response.data && response.data.length > 0) {
      const lastResponse = response.data[response.data.length - 1]
      businessDate = lastResponse.currentDate
      dispatch(setBusinessDate(lastResponse))
      return { payload: businessDate, success: true, id: lastResponse.id }
    } else {
      console.error('No business date found in the response')
    }
  } catch (error) {
    console.error('Business Date Error. Reverted Back to new Date(): ', error)
    businessDate = new Date()
  }
}

export const nextBusinessDate =
  (businessDateInfo: BusinessDateParams | null) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl

    if (!businessDateInfo) {
      const initResult = await dispatch(
        saveInitialBusinessDate(todaysBusinessDate) as any,
      )
      if (initResult.success) {
        businessDateInfo = initResult.payload
      } else {
        console.error(
          'Failed to save the initial business date:',
          initResult.payload,
        )
        return { success: false, error: initResult.payload }
      }
    }

    // Add a type assertion or a null check here to avoid the error
    if (!businessDateInfo) {
      throw new Error(
        'businessDateInfo should not be null after initialization',
      )
    }

    return client
      .post(`${apiServerUrl}/api/business-dates/next`, {
        id: businessDateInfo.id,
      })
      .then(response => {
        const fullResponse: any = response.data
        businessDate = fullResponse.currentDate
        dispatch(setBusinessDate(fullResponse))
        return { payload: businessDate, success: true, id: fullResponse.id }
      })
      .catch(error => {
        console.log('Business Date Error. Reverted Back to new Date(): ', error)
        businessDate = new Date()
        return
      })
  }

export const getBusinessDateFromState = () => {
  return businessDate
}
