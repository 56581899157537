import { EntityParams } from 'app/models/entity-params'
import { FacilityParams } from 'app/models/facility-params'
import { FeePaymentParams } from 'app/models/fee-payment-params'
import { Fees } from 'app/models/fees-params'
import { LoanParams } from 'app/models/loan-params'
import { LoanRateSettingParams } from 'app/models/loan-rate-setting-params'
import { LoanRolloverParams } from 'app/models/loan-rollover-params'
import { LoanIncreaseParams } from 'app/models/loan-increase-params'
import { PikParams } from 'app/models/pik-params'
import { PrincipalPaymentParams } from 'app/models/principal-payments-params'
import { ServicingParams, ServicingTypes } from 'app/models/servicing-params'
import { getNextFeeAmountBulk } from 'app/state/ducks/fees/thunks'
import { stringToDate, getTransactionLabel, mapEntityIdsToNames } from 'app/utils/util-functions'
import moment from 'moment'
import { LoanConversionParams } from 'app/models/loan-conversion-params'
import { DealParams } from 'app/models/deal-params'
import cleanUUID from 'app/views/components/functions/cleanUUID'

// Memoization function
const memoize = (fn: any) => {
  const cache = new Map()
  return (...args: any) => {
    const key = JSON.stringify(args)
    if (cache.has(key)) {
      return cache.get(key)
    }
    const result = fn(...args)
    cache.set(key, result)
    return result
  }
}

export const handleServicingRowClicked = memoize((
  e: ServicingParams,
  navigate: any,
  allLoans: LoanParams[],
  allFacilities: FacilityParams[]
) => {
  switch (e.type) {
    case ServicingTypes.principalPayment:
    case ServicingTypes.loanRollover:
    case ServicingTypes.loanRateSetting:
    case ServicingTypes.loanConversion:
    case ServicingTypes.loanIncrease: {
      const currentFacility = findFacilityByLoanId(
        e.original.loanId.id,
        allLoans,
        allFacilities
      )
      if (currentFacility) {
        navigate(
          '/dealmanagement/facilities/' +
          currentFacility.id +
          '/' +
          currentFacility.status +
          '/loans'
        )
      }
      return
    }

    case ServicingTypes.loanAddRates: {
      const currentFacility = findFacilityByLoanId(
        e.original.id,
        allLoans,
        allFacilities
      )
      if (currentFacility) {
        navigate(
          '/dealmanagement/facilities/' +
          currentFacility.id +
          '/' +
          currentFacility.status +
          '/loans'
        )
      }
      return
    }
    case ServicingTypes.pik:
    case ServicingTypes.maturingLoan:
    case ServicingTypes.pikScheduleLoan:
    case ServicingTypes.newBorrowing: {
      const currentFacility = findFacilityByLoanId(
        e.id,
        allLoans,
        allFacilities
      )
      if (currentFacility) {
        navigate(
          '/dealmanagement/facilities/' +
          currentFacility.id +
          '/' +
          currentFacility.status +
          '/loans'
        )
      }
      return
    }
    case ServicingTypes.amortizationSchedule: {
      navigate(
        '/dealmanagement/facilities/' +
        e.id +
        '/' +
        e.original.status +
        '/amortization-schedule'
      )
      return
    }
    case ServicingTypes.maturingFacility: {
      navigate(
        '/dealmanagement/facilities/' +
        e.id +
        '/' +
        e.original.status
      )
      return
    }
    case ServicingTypes.maturingDeal: {
      navigate('/dealmanagement/deal/' + e.id + '/' + e.original.status)
      return
    }
    case ServicingTypes.base: {
      console.log('Hardcoded Service', e)
      return
    }
  }
})

export const findFacilityByLoanId = memoize((
  loanId: string,
  allLoans: LoanParams[],
  allFacilities: FacilityParams[]
) => {
  const currentLoan = allLoans.find(loan => loan.id && loan.id === loanId)
  const currentFacility = currentLoan?.facilityId
    ? allFacilities.find(
      facility => facility.id && facility.id === currentLoan.facilityId.id
    )
    : null
  return currentFacility
})

export const fetchPrincipals = memoize((
  allPrincipals: PrincipalPaymentParams[],
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[],
) => {
  return allPrincipals
    .filter(pp => pp.id && pp.status != 'Approved')
    .map(principal => {
      const currentFacility = findFacilityByLoanId(
        principal.loanId.id,
        allLoans,
        allFacilities
      )
      const name = currentFacility?.name ?? principal.id
      const currentLoan = allLoans.find(
        loan => loan.id && loan.id === principal.loanId.id
      )

      const transactionType = principal?.paymentType
        ? getTransactionLabel(principal.paymentType, principal.interestPaid)
        : ''

      return {
        id: principal.id ?? '',
        facilityName: name ?? '',
        stage: principal.status ?? '',
        transaction: transactionType,
        status: principal.status ?? '',
        amount: Number(principal.acceptedAmount) ?? 0,
        currency: currentLoan?.currency ?? '',
        serviceTime: stringToDate(principal.paymentDate),
        admin:
          allEntities.find(
            entity => entity.id == currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: stringToDate(principal.paymentDate),
        scheduled: 'unscheduled',
        type: ServicingTypes.principalPayment,
        original: principal,
      }
    })
})

export const fetchPiks = memoize((
  allPiks: PikParams[],
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[]
) => {
  return allPiks
    .filter(pik => pik.id && pik.status != 'Approved')
    .map(pik => {
      const currentFacility = findFacilityByLoanId(
        pik.loanId.id,
        allLoans,
        allFacilities
      )
      const name = currentFacility?.name ?? pik.id
      const currentLoan = allLoans.find(
        loan => loan.id && loan.id === pik.loanId.id
      )

      return {
        id: pik.id ?? '',
        facilityName: name ?? '',
        stage: pik.status ?? '',
        transaction: 'PIK',
        status: pik.status ?? '',
        amount: Number(pik.interestAmount) ?? 0,
        currency: currentLoan?.currency ?? '',
        serviceTime: new Date(pik.pikDate),
        admin:
          allEntities.find(
            entity => entity.id == currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: new Date(pik.pikDate),
        scheduled: 'unscheduled',
        type: ServicingTypes.pik,
        original: pik,
      }
    })
})

export const fetchFeePayments = memoize((
  allFeePayments: FeePaymentParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[]
) => {
  return allFeePayments
    .filter(feePayment => feePayment.id && feePayment.status != 'Approved')
    .map(feePayment => {
      const currentFacility = allFacilities.find(
        facility => facility.id && facility.id === feePayment.facilityId.id
      )
      const name = currentFacility?.name ?? feePayment.id

      return {
        id: feePayment.id ?? '',
        facilityName: name ?? '',
        stage: feePayment.status ?? '',
        transaction: 'Fee Payment',
        status: feePayment.status ?? '',
        amount: Number(feePayment.amount) ?? 0,
        currency: currentFacility?.baseCurrency ?? '',
        serviceTime: new Date(feePayment.effectiveDate),
        admin:
          allEntities.find(
            entity => entity.id == currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: new Date(feePayment.effectiveDate),
        scheduled: 'scheduled',
        type: ServicingTypes.feePayment,
        original: feePayment,
      }
    })
})

export const fetchFees = memoize(async (
  allFees: Fees[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[],
  effectiveDate: Date
) => {
  const filteredFees = allFees.filter(fee =>
    fee.id && moment(fee.firstPaymentDate).diff(moment(effectiveDate), 'days') <= 3
  );
  const bulkFees = await getNextFeeAmountBulk(filteredFees)
  const mappedFees = filteredFees.map(async fee => {
    const currentFacility = allFacilities.find(
      facility => facility.id && facility.id === fee.facilityId.id
    );
    const name = currentFacility?.name ?? fee.id;
    const feeId = fee.id ?? '';
    return {
      id: feeId,
      facilityName: name ?? '',
      stage: 'Pending Review',
      transaction: 'Fee',
      status: 'Pending',
      amount: feeId ? bulkFees?.payload[feeId]?.amount : 0,
      currency: currentFacility?.baseCurrency ?? '',
      serviceTime: new Date(fee.firstPaymentDate),
      admin: allEntities.find(
        entity => entity.id == currentFacility?.adminEntity.id
      )?.entityName ?? '',
      notices: 'Pending',
      effectiveDate: new Date(fee.firstPaymentDate),
      scheduled: 'scheduled',
      type: ServicingTypes.fee,
      original: fee,
    };
  });

  return await Promise.all(mappedFees);

})

export const fetchIncreases = memoize((
  loanIncreases: LoanIncreaseParams[],
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[]
) => {
  return loanIncreases
    .filter(li => li.id)
    .map(li => {
      const currentFacility = findFacilityByLoanId(
        li.loanId.id,
        allLoans,
        allFacilities
      )
      const name = currentFacility?.name ?? li.id
      const currentLoan = allLoans.find(
        loan => loan.id && loan.id === li.loanId.id
      )

      return {
        id: li.id ?? '',
        facilityName: name ?? '',
        stage: li.status,
        transaction: 'Loan Increase',
        status: li.status,
        amount: Number(li.amount) ?? 0,
        currency: currentLoan?.currency ?? 'USD',
        serviceTime: stringToDate(li.effectiveDate),
        admin:
          allEntities.find(
            entity => entity.id === currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: stringToDate(li.effectiveDate),
        scheduled: 'unscheduled',
        type: ServicingTypes.loanIncrease,
        original: li,
      }
    })
})


export const fetchConversions = memoize((
  loanConversions: LoanConversionParams[],
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[]
) => {
  return loanConversions
    .filter(lc => lc.id)
    .map(lc => {
      const currentFacility = findFacilityByLoanId(
        lc.loanId.id,
        allLoans,
        allFacilities
      )
      const name = currentFacility?.name ?? lc.id
      const currentLoan = allLoans.find(
        loan => loan.id && loan.id === lc.loanId.id
      )

      return {
        id: lc.id ?? '',
        facilityName: name ?? '',
        stage: lc.status,
        transaction: 'Loan Conversion',
        status: lc.status,
        amount: Number(lc.newLoan.amount) ?? 0,
        currency: currentLoan?.currency ?? 'USD',
        serviceTime: stringToDate(lc.newLoan.startDate),
        admin:
          allEntities.find(
            entity => entity.id === currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: stringToDate(lc.newLoan.startDate),
        scheduled: 'unscheduled',
        type: ServicingTypes.loanConversion,
        original: lc,
      }
    })
})

export const fetchRollovers = memoize((
  loanRollovers: LoanRolloverParams[],
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[]
) => {
  return loanRollovers
    .filter(lr => lr.id)
    .map(lr => {
      const currentFacility = findFacilityByLoanId(
        lr.loanId.id,
        allLoans,
        allFacilities
      )
      const name = currentFacility?.name ?? lr.id
      const currentLoan = allLoans.find(
        loan => loan.id && loan.id === lr.loanId.id
      )

      return {
        id: lr.id ?? '',
        facilityName: name ?? '',
        stage: lr.status,
        transaction:
          currentLoan?.amount == lr.amount
            ? 'Rollover'
            : lr.withPik
              ? 'Rollover with PIK' :
              Number(currentLoan?.amount ?? 0.0) < Number(lr.amount ?? 0.0)
                ? 'Rollover With New Borrowing'
                : 'Rollover With Principal',
        status: lr.status,
        amount: Number(lr.amount) ?? 0,
        currency: lr.currency ?? 'USD',
        serviceTime: stringToDate(lr.startDate),
        admin:
          allEntities.find(
            entity => entity.id === currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: stringToDate(lr.startDate),
        scheduled: 'unscheduled',
        type: ServicingTypes.loanRollover,
        original: lr,
      }
    })
})

export const fetchRateSettings = memoize((
  loanRateSettings: LoanRateSettingParams[],
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[]
) => {
  return loanRateSettings
    .filter(lr => lr.id)
    .map(lr => {
      const currentFacility = findFacilityByLoanId(
        lr.loanId.id,
        allLoans,
        allFacilities
      )
      const name = currentFacility?.name ?? lr.id
      const currentLoan = allLoans.find(
        loan => loan.id && loan.id === lr.loanId.id
      )

      return {
        id: lr.id ?? '',
        facilityName: name ?? '',
        stage: lr.status,
        transaction: 'Rate Setting',
        status: lr.status,
        amount: Number(currentLoan?.amount) ?? 0,
        currency: currentLoan?.currency ?? 'USD',
        serviceTime: currentLoan?.startDate ?? '',
        admin:
          allEntities.find(
            entity => entity.id === currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: currentLoan?.startDate ?? '',
        scheduled: 'unscheduled',
        type: ServicingTypes.loanRateSetting,
        original: lr,
      }
    })
})

export const checkLoanRateSettings = memoize((
  loanRateSettings: LoanRateSettingParams[],
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[]
) => {
  const loanWithRates = loanRateSettings.map(x => x.loanId.id)

  return allLoans
    .filter(
      lr =>
        lr.status &&
        lr.status === 'Approved' &&
        (lr.interestBaseRate == null || lr.interestBaseRate == 0.0) &&
        !loanWithRates.includes(lr.id ?? '')
    )
    .map(lr => {
      const currentFacility = findFacilityByLoanId(
        lr.id ?? '',
        allLoans,
        allFacilities
      )
      const name = currentFacility?.name ?? lr.id
      const currentLoan = allLoans.find(loan => loan.id && loan.id === lr.id)

      return {
        id: lr.id ?? '',
        facilityName: name ?? '',
        stage: 'Pending Review',
        transaction: 'Add Rates',
        status: 'Pending',
        amount: Number(currentLoan?.amount) ?? 0,
        currency: currentLoan?.currency ?? 'USD',
        serviceTime: currentLoan?.startDate ?? '',
        admin:
          allEntities.find(
            entity => entity.id === currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: currentLoan?.startDate ?? '',
        scheduled: 'unscheduled',
        type: ServicingTypes.loanAddRates,
        original: lr,
      }
    })
})

export const fetchMaturingLoan = memoize((
  loanRollovers: LoanRolloverParams[],
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[],
  effectiveDate: Date,

) => {
  const rolloverLoanId = loanRollovers.map(lr => lr.loanId.id)

  return allLoans
    .filter(
      loan =>
        loan.id &&
        loan.status &&
        loan.status === 'Approved' &&
        !rolloverLoanId.includes(loan.id) &&
        moment(loan.endDate).diff(moment(effectiveDate), 'days') <= 3
    )
    .map(loan => {
      const currentFacility = findFacilityByLoanId(
        loan.id ?? '',
        allLoans,
        allFacilities
      )

      return {
        id: loan.id ?? '',
        facilityName: currentFacility?.name ?? loan.id ?? '',
        stage: 'Pending Review',
        transaction: 'Maturing Loan',
        status: 'Pending',
        amount: Number(loan.amount),
        currency: loan?.currency ?? 'USD',
        serviceTime: stringToDate(loan.endDate),
        admin:
          allEntities.find(
            entity => entity.id === currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: stringToDate(loan.endDate),
        scheduled: 'scheduled',
        type: ServicingTypes.maturingLoan,
        original: loan as any,
      }
    })
})

export const fetchPikLoan = memoize((
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[],
  effectiveDate: Date,
  piks: PikParams[],
) => {
  const pikIds = piks.map(pik => pik.loanId.id)

  return allLoans
    .filter(
      loan =>
        loan.id &&
        loan.status &&
        loan.status === 'Approved' &&
        loan.nextPIKDate &&
        !pikIds.includes(loan.id) &&
        moment(loan.nextPIKDate).diff(moment(effectiveDate), 'days') <= 3
    )
    .map(loan => {
      const currentFacility = findFacilityByLoanId(
        loan.id ?? '',
        allLoans,
        allFacilities
      )
      return {
        id: loan.id ?? '',
        facilityName: currentFacility?.name ?? loan.id ?? '',
        stage: 'Pending Review',
        transaction: 'PIK Schedule Loan',
        status: 'Pending',
        amount: Number(loan.amount),
        currency: loan?.currency ?? 'USD',
        serviceTime: stringToDate(loan.nextPIKDate ?? ''),
        admin:
          allEntities.find(
            entity => entity.id === currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: stringToDate(loan.nextPIKDate ?? ''),
        scheduled: 'scheduled',
        type: ServicingTypes.pikScheduleLoan,
        original: loan as any,
      }
    })
})

export const fetchMaturingFacilities = memoize((
  allEntities: EntityParams[],
  allFacilities: FacilityParams[],
  effectiveDate: Date,
) => {

  return allFacilities
    .filter(
      facility =>
        facility.id &&
        facility.status &&
        facility.status === 'Approved' &&
        moment(facility.maturity).diff(moment(effectiveDate), 'days') <= 3
    )
    .map(facility => ({

      id: facility.id ?? '',
      facilityName: facility.name ?? '',
      stage: 'Pending Review',
      transaction: 'Maturing Facility',
      status: 'Pending',
      amount: Number(facility.amount),
      currency: facility?.baseCurrency ?? 'USD',
      serviceTime: stringToDate(facility.maturity),
      admin:
        allEntities.find(
          entity => entity.id === facility?.adminEntity.id
        )?.entityName ?? '',
      notices: 'Pending',
      effectiveDate: stringToDate(facility.maturity),
      scheduled: 'unscheduled',
      type: ServicingTypes.maturingFacility,
      original: facility as any,

    }))
})

export const fetchMaturingDeals = memoize((
  allDeals: DealParams[],
  allEntities: EntityParams[],
  effectiveDate: Date,
) => {
  const entityIdToName = mapEntityIdsToNames(allEntities);
  return allDeals
    .filter(
      deal =>
        deal.id &&
        deal.status &&
        deal.status === 'Approved' &&
        moment(deal.maturity).diff(moment(effectiveDate), 'days') <= 3
    ).map(deal => ({
      id: deal.id ?? '',
      facilityName: deal.dealName ?? '',
      stage: 'Pending Review',
      transaction: 'Maturing Deal',
      status: 'Pending',
      amount: Number(deal.amount),
      currency: deal?.currency ?? 'USD',
      serviceTime: stringToDate(deal.maturity),
      admin: deal.adminEntity.map((adminEntity: { id: string }) => {
        return entityIdToName.get(cleanUUID(adminEntity.id)) ?? '';
      }),
      notices: 'Pending',
      effectiveDate: stringToDate(deal.maturity),
      scheduled: 'unscheduled',
      type: ServicingTypes.maturingDeal,
      original: deal as any,
    })
    )
})

export const fetchAmortizationScheduleFacility = memoize((
  allFacilities: FacilityParams[],
  allEntities: EntityParams[],
  effectiveDate: Date,
) => {
  return allFacilities
    .filter(
      facility =>
        facility.status &&
        facility.status === 'Approved' &&
        moment(facility.nextAmortizationPaymentData?.paymentDate).diff(moment(effectiveDate), 'days') <= 3
    )
    .map(facility => {
      return {
        id: facility.id ?? '',
        facilityName: facility?.name,
        stage: 'Pending Review',
        transaction: 'Amortization Schedule',
        status: 'Pending',
        amount: Number(facility.nextAmortizationPaymentData?.paymentAmount ?? facility.amount),
        currency: facility?.baseCurrency ?? 'USD',
        serviceTime: stringToDate(facility.nextAmortizationPaymentData?.paymentDate ?? ''),
        admin:
          allEntities.find(
            entity => entity.id === facility?.adminEntity.id
          )?.entityName ?? '',
        notices: 'Pending',
        effectiveDate: stringToDate(facility.nextAmortizationPaymentData?.paymentDate ?? ''),
        scheduled: 'scheduled',
        type: ServicingTypes.amortizationSchedule,
        original: facility as any,
      }
    })
})

export const fetchNewBorrowings = memoize((
  allLoans: LoanParams[],
  allEntities: EntityParams[],
  allFacilities: FacilityParams[]
) => {
  return allLoans
    .filter(loan => loan.id && loan.status && loan.status !== 'Approved')
    .map(loan => {
      const currentFacility = findFacilityByLoanId(
        loan.id ?? '',
        allLoans,
        allFacilities
      )

      return {
        id: loan.id ?? '',
        facilityName: currentFacility?.name ?? loan.id ?? '',
        stage: loan.status ?? '',
        transaction: 'New Borrowing',
        status: loan.status ?? '',
        amount: Number(loan.amount),
        currency: loan.currency ?? 'USD',
        serviceTime: stringToDate(loan.endDate),
        admin:
          allEntities.find(
            entity => entity.id === currentFacility?.adminEntity.id
          )?.entityName ?? '',
        notices: loan.status ?? '',
        effectiveDate: stringToDate(loan.startDate),
        scheduled: 'unscheduled',
        type: ServicingTypes.newBorrowing,
        original: loan as any,
      }
    })
})
