import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'
import { SelectWithTable } from 'app/views/components/inputs/selects/CustomSelectWithTable';
import { TradeEntryEntityTable } from './trade-entry-entity-table';
import { getEntityTypes } from 'app/state/ducks/entity-type/selectors';
import { getMyEntities } from 'app/utils/util-functions';
import { EntityParams } from 'app/models/entity-params';

type Props = {
  onChange: (value?: string | null) => void;
  value: string;
  isRight?: boolean;
  loading: boolean;
};

export function TradeEntryEntitySearch({ onChange, value, isRight, loading }: Props) {

  const entities = useSelector(getEntities); // to be displayed in counterparty dropdown (where isRight is true)
  // const myEntities = useSelector(getMyEntities); // to be displayed in My Entity dropdown (where isRight is false)
  const entityTypes = useSelector(getEntityTypes);
  const entityProfiles = useSelector(getEntityProfiles)

  // get my entities from list of all entities
  const myEntities = getMyEntities(entities, entityProfiles);

  // Map entities with their respective entity type names to be displayed in dropdown selection table
  const mapEntitiesWithTypeName = (entityList: EntityParams[]): EntityParams[] => {
    return entityList.map((entity) => ({
      ...entity,
      entityTypeName: entityTypes.find((type) => type.id === entity?.entityType?.id)?.name ?? '',
    }));
  };

  const entWithTypeName = mapEntitiesWithTypeName(entities); // to be displayed in counterparty dropdown
  const myEntWithTypeName = mapEntitiesWithTypeName(myEntities); // // to be displayed in My Entity dropdown

  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility
  const [searchValue, setSearchValue] = useState(value ?? ''); // State to control the search filter
  const [filteredEntities, setFilteredEntities] = useState(isRight ? entWithTypeName : myEntWithTypeName); // State to store the filtered entities

  const filterEntities = (entitiesToFilter: EntityParams[]) => {
    return entitiesToFilter.filter((e: EntityParams) =>
      e.entityName.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.mei?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.internalId?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.entityTypeName?.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  useEffect(() => {
    const sourceEntities = isRight ? entWithTypeName : myEntWithTypeName;
    setFilteredEntities(filterEntities(sourceEntities));
  }, [searchValue, entities, entityTypes]);

  useEffect(() => {
    // if (filteredEntities.length === 1) {
    //   onChange(filteredEntities[0].id);
    // }
    if (filteredEntities.length > 1) {
      onChange(null);
    }
  }, [filteredEntities]);

  useEffect(() => {
    if (!value) {
      setSearchValue('');
    }
  }, [value])

  const handleSelectChange = (value: string) => {
    const selectedEntity = filteredEntities.find((e) => e.id === value);
    setSearchValue(selectedEntity?.entityName ?? ''); // Set search value to the selected entity name
    onChange(value); // Notify parent component of the selection
    setDropdownOpen(false); // Close dropdown after selection
  };


  return (
    <SelectWithTable
      searchValue={searchValue}
      open={dropdownOpen}
      setOpen={setDropdownOpen}
      setSearchValue={setSearchValue}
      disabled={loading}
      classNames={{ dropdown: isRight ? 'tradeEntrySelectEntityRight' : 'tradeEntrySelectEntityLeft' }}>

      <TradeEntryEntityTable data={filteredEntities} handleSelectChange={handleSelectChange} searchValue={searchValue} />
    </SelectWithTable>
  );
}
