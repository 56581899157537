import { Stack } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getLoanHistory } from 'app/state/ducks/loans/selectors'
import { loadLoanHistory } from 'app/state/ducks/loans/thunks'
import { LoanActionType } from 'app/models/dropdown-options'
import { formatDateToShow, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import MainTable from 'app/views/components/Table/MainTable'
import { CleanedLoanHistoryData, LoanHistoryData } from 'app/models/loan-params'
import { getColumnDefs } from './table-columnDefs-loan-history'

type Props = {
  close: any
  loanId: string
}

export default function HistoryForm({ loanId }: Props) {
  const loanHistory = useSelector(getLoanHistory)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadLoanHistory(loanId))
  }, [loanId])

  function cleanLoanHistory(
    loanHistory: LoanHistoryData[],
  ): CleanedLoanHistoryData[] {
    return loanHistory.map((item, index) => {
      const transactionAmount = index === 0 ? Number(item.loan.amount) : Number(item.loan.amount) - Number(loanHistory[index - 1].loan.amount)
      const exportObj: CleanedLoanHistoryData = {
        'Effective Date': formatDateToShow(item?.effectiveDate ? stringToDate(item.effectiveDate) : undefined),
        'Transaction Type': LoanActionType.find(
          option => option.value == item.actionType
        )?.label ?? item.actionType,
        'Transaction Amount': formatNumberToCurrency(transactionAmount, item.loan?.currency ?? 'USD'),
        'New Balance': formatNumberToCurrency(Number(item.loan.amount), item.loan?.currency ?? 'USD'),
        'Base Rate': item.loan.interestBaseRate ?? '',
        'Margin': item.loan.margin,
        'All-In Rate': item.loan.allInRate + '',
      };

      return exportObj;
    })
  }

  return (
    <Stack w="100%">
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Loan History
      </div>
      <MainTable
        tableName='Loan History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs()}
        data={cleanLoanHistory(loanHistory)}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        setRow={() => {
        }}
      />
    </Stack>
  )
}