import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDeals } from 'app/state/ducks/deals/selectors';
import { SelectWithTable } from 'app/views/components/inputs/selects/CustomSelectWithTable';
import { TradeEntryDealTable } from './trade-entry-deal-table';

type Props = {
  onChange: (value?: string | null) => void;
  value: string;
  loading: boolean;
};

export function TradeEntryDealSearch({ onChange, value, loading }: Props) {

  const deals = useSelector(getDeals);

  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility
  const [searchValue, setSearchValue] = useState(value ?? ''); // State to control the search filter
  const [filteredDeals, setFilteredDeals] = useState(deals); // State to store the filtered deals

  useEffect(() => {
    setFilteredDeals(deals.filter((d) =>
      d.dealName.toLowerCase().includes(searchValue.toLowerCase()) ||
      d.cusip?.toLowerCase().includes(searchValue.toLowerCase()) ||
      d.lxId?.toLowerCase().includes(searchValue.toLowerCase()) ||
      d.id.toLowerCase().includes(searchValue.toLowerCase())
    ))
  }, [searchValue, deals]);

  useEffect(() => {
    // if (filteredDeals.length === 1) {
    //   handleSelectChange(filteredDeals[0].dealName);
    // }
    if (filteredDeals.length > 1) {
      onChange(null);
    }
  }, [filteredDeals]);

  useEffect(() => {
    if (!value) {
      setSearchValue('');
    }
  }, [value])


  const handleSelectChange = (value: string) => {
    const selectedDeal = filteredDeals.find((e) => e.id === value);
    setSearchValue(selectedDeal?.dealName ?? ''); // Set search value to the selected deal name
    onChange(value); // Notify parent component of the selection
    setDropdownOpen(false); // Close dropdown after selection
  };


  return (
    <SelectWithTable
      disabled={loading}
      searchValue={searchValue}
      open={dropdownOpen}
      setOpen={setDropdownOpen}
      setSearchValue={setSearchValue}
    >
      <TradeEntryDealTable data={filteredDeals} handleSelectChange={handleSelectChange} searchValue={searchValue} />
    </SelectWithTable>
  );
}
